import { Button, Col, Divider, Row, Spin, Steps, message } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  getUserData,
  pdfGenerate,
  verifyTenantData,
} from "store/reducers/user.reducer";
import PayStubsDetails from "./PayStubsDetails";
import EmployerDetails from "./EmployerDetails";
import RentalHistory from "./RentalHistory";
import CreditScoreDetails from "./CreditScoreDetails";
import { DeleteAccountSvg } from "assets/svgs";
import { upperCase } from "pages/utils/fn";
import GeneralProfile from "./GeneralProfile";
import StateProfile from "./StateProfile";
import { HiDownload } from "react-icons/hi";
import FileSaver from "file-saver";
import { pdf } from "@react-pdf/renderer";
import ProfilePdf from "./application-pdf/ProfilePdf";

const ApplicationDetails = ({ onCancel, userName, updatedData }) => {
  const { Step } = Steps;
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [userDataObj, setUserDataObj] = useState({});
  const [pdfUrl, setPdfUrl] = useState("");
  const [generalProfile, setgeneralProfile] = useState("wait");
  const [creditScore, setcreditScore] = useState("wait");
  const [payStubs, setpayStubs] = useState("wait");
  const [employerDetails, setemployerDetails] = useState("wait");
  const [rentalHistory, setrentalHistory] = useState("wait");
  const [stateProfile, setstateProfile] = useState("wait");
  const [noData, setNoData] = useState(false);
  const dispatch = useDispatch();

  const onChangeTab = (value) => {
    setCurrentStep(value);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getUserData()).then(({ payload }) => {
      if (payload?.Error) {
        setLoading(false);
        setNoData(true);
      } else {
        setLoading(false);
        setUserDataObj(payload);
        setNoData(false);
      }
    });

    if (localStorage.getItem("app_id")) {
      dispatch(pdfGenerate()).then(({ payload }) => {
        if (payload?.key) {
          setPdfUrl(payload?.key);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (userDataObj?.id) {
      if (userDataObj?.is_general_profile_verified === 1) {
        setgeneralProfile("finish");
      } else {
        setgeneralProfile("wait");
      }
      if (userDataObj?.is_credit_score_verified === 1) {
        setcreditScore("finish");
      } else {
        setcreditScore("wait");
      }
      if (userDataObj?.is_paystub_verified === 1) {
        setpayStubs("finish");
      } else {
        setpayStubs("wait");
      }
      if (userDataObj?.is_employment_verified === 1) {
        setemployerDetails("finish");
      } else {
        setemployerDetails("wait");
      }
      if (userDataObj?.is_rental_history_verified === 1) {
        setrentalHistory("finish");
      } else {
        setrentalHistory("wait");
      }
      if (userDataObj?.is_state_profile_verified === 1) {
        setstateProfile("finish");
      } else {
        setstateProfile("wait");
      }
    }
  }, [userDataObj]);

  useEffect(() => {
    if (userDataObj?.id) {
      if (userDataObj?.is_general_profile_verified !== 1) {
        setCurrentStep(0);
      } else if (userDataObj?.is_credit_score_verified !== 1) {
        setCurrentStep(1);
      } else if (userDataObj?.is_paystub_verified !== 1) {
        setCurrentStep(2);
      } else if (userDataObj?.is_employment_verified !== 1) {
        setCurrentStep(3);
      } else if (userDataObj?.is_rental_history_verified !== 1) {
        setCurrentStep(4);
      } else if (userDataObj?.is_state_profile_verified !== 1) {
        setCurrentStep(5);
      }
    }
  }, [userDataObj]);

  const onFinish = (val) => {
    if (
      (val?.is_credit_score_verified
        ? val?.is_credit_score_verified === 1
        : userDataObj?.is_credit_score_verified === 1) &&
      (val?.is_employment_verified
        ? val?.is_employment_verified === 1
        : userDataObj?.is_employment_verified === 1) &&
      (val?.is_general_profile_verified
        ? val?.is_general_profile_verified === 1
        : userDataObj?.is_general_profile_verified === 1) &&
      (val?.is_paystub_verified
        ? val?.is_paystub_verified === 1
        : userDataObj?.is_paystub_verified === 1) &&
      (val?.is_rental_history_verified
        ? val?.is_rental_history_verified === 1
        : userDataObj?.is_rental_history_verified === 1) &&
      (val?.is_state_profile_verified
        ? val?.is_state_profile_verified === 1
        : userDataObj?.is_state_profile_verified === 1)
    ) {
      message.success("Tenant Verified successfully");
      onCancel(false);
    }
  };

  // Calling API upon approval
  const onApprovalSubmit = (val, id) => {
    let data = {};
    if (val === "general_profile") {
      data.document_name = "general_profile";
      data.user_id = id;
      data.status = 1;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setUserDataObj(payload);
        updatedData(payload);
        onFinish(payload);
      });
    } else if (val === "credit_score") {
      data.document_name = "credit_score";
      data.user_id = id;
      data.status = 1;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setUserDataObj(payload);
        updatedData(payload);
        onFinish(payload);
      });
    } else if (val === "pay_stubs") {
      data.document_name = "pay_stubs";
      data.user_id = id;
      data.status = 1;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setUserDataObj(payload);
        updatedData(payload);
        onFinish(payload);
      });
    } else if (val === "employer_details") {
      data.document_name = "employer_details";
      data.user_id = id;
      data.status = 1;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setUserDataObj(payload);
        updatedData(payload);
        onFinish(payload);
      });
    } else if (val === "rental_history") {
      data.document_name = "rental_history";
      data.user_id = id;
      data.status = 1;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setUserDataObj(payload);
        updatedData(payload);
        onFinish(payload);
      });
    } else if (val === "state_profile") {
      data.document_name = "state_profile";
      data.user_id = id;
      data.status = 1;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setUserDataObj(payload);
        updatedData(payload);
        onFinish(payload);
      });
    }
  };

  // Calling API upon decline
  const onDeclineSubmit = (val, id) => {
    let data = {};
    if (val === "general_profile") {
      data.document_name = "general_profile";
      data.user_id = id;
      data.status = 2;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setUserDataObj(payload);
        updatedData(payload);
      });
    } else if (val === "credit_score") {
      data.document_name = "credit_score";
      data.user_id = id;
      data.status = 2;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setUserDataObj(payload);
        updatedData(payload);
      });
    } else if (val === "pay_stubs") {
      data.document_name = "pay_stubs";
      data.user_id = id;
      data.status = 2;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setUserDataObj(payload);
        updatedData(payload);
      });
    } else if (val === "employer_details") {
      data.document_name = "employer_details";
      data.user_id = id;
      data.status = 2;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setUserDataObj(payload);
        updatedData(payload);
      });
    } else if (val === "rental_history") {
      data.document_name = "rental_history";
      data.user_id = id;
      data.status = 2;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setUserDataObj(payload);
        updatedData(payload);
      });
    } else if (val === "state_profile") {
      data.document_name = "state_profile";
      data.user_id = id;
      data.status = 2;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setUserDataObj(payload);
        updatedData(payload);
      });
    }
  };

  const downloadInvoice = async () => {
    const blob = await pdf(
      <ProfilePdf applicationProcessData={userDataObj} />
    ).toBlob();
    FileSaver.saveAs(blob, `Profile Data`);
  };

  return (
    <ApplicationDetailsWrap>
      {loading ? (
        <div style={{ top: "50%", position: "absolute", left: "50%" }}>
          <Spin className="ant__spin" size="large" />
        </div>
      ) : noData ? (
        <NoDataWrap>
          <DeleteAccountSvg />
          <h1>No Data Found</h1>
        </NoDataWrap>
      ) : (
        <div className="step__divider">
          <Row justify="space-between">
            <Col>
              <h1>{upperCase(userName)}</h1>
            </Col>
            <Col>
              <Col style={{ marginRight: "20px" }}>
                <HiDownload
                  className="share__class"
                  onClick={() => downloadInvoice()}
                />
                <span
                  className="share__class share__class__span"
                  onClick={() => downloadInvoice()}
                >
                  Download user data
                </span>
              </Col>
            </Col>
          </Row>
          <ApplicationProccess>
            <Steps
              direction="horizontal"
              size="large"
              onChange={onChangeTab}
              current={currentStep}
            >
              <Step
                className="application__width"
                status={generalProfile}
                title="General Profile"
              />
              <Step
                className="application__width"
                title="Credit Score"
                status={creditScore}
              />
              <Step
                className="application__width"
                title="PayStubs "
                status={payStubs}
              />
              <Step
                className="application__width"
                title="Employer Details"
                status={employerDetails}
              />
              <Step
                className="application__width"
                title="Rental History"
                status={rentalHistory}
              />
              <Step
                className="application__width"
                title="State Profile"
                status={stateProfile}
              />
            </Steps>
          </ApplicationProccess>
          <Divider type="horizontal" />
          {currentStep === 0 ? (
            <GeneralProfile
              onChangeTab={onChangeTab}
              onApprovalSubmit={onApprovalSubmit}
              onDeclineSubmit={onDeclineSubmit}
              userDataObj={userDataObj}
              onCancel={onCancel}
            />
          ) : currentStep === 1 ? (
            <CreditScoreDetails
              onApprovalSubmit={onApprovalSubmit}
              onDeclineSubmit={onDeclineSubmit}
              userDataObj={userDataObj}
              onChangeTab={onChangeTab}
            />
          ) : currentStep === 2 ? (
            <PayStubsDetails
              onApprovalSubmit={onApprovalSubmit}
              onDeclineSubmit={onDeclineSubmit}
              userDataObj={userDataObj}
              onChangeTab={onChangeTab}
              pdfUrl={pdfUrl}
            />
          ) : currentStep === 3 ? (
            <EmployerDetails
              onApprovalSubmit={onApprovalSubmit}
              onDeclineSubmit={onDeclineSubmit}
              userDataObj={userDataObj}
              onChangeTab={onChangeTab}
            />
          ) : currentStep === 4 ? (
            <RentalHistory
              onApprovalSubmit={onApprovalSubmit}
              onDeclineSubmit={onDeclineSubmit}
              userDataObj={userDataObj}
              onChangeTab={onChangeTab}
            />
          ) : currentStep === 5 ? (
            <StateProfile
              onApprovalSubmit={onApprovalSubmit}
              onDeclineSubmit={onDeclineSubmit}
              userDataObj={userDataObj}
              onChangeTab={onChangeTab}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </ApplicationDetailsWrap>
  );
};

export default ApplicationDetails;

const ApplicationDetailsWrap = styled.div`
  min-height: 500px;

  .ant-divider-horizontal {
    margin: 10px 0;
  }

  .share__class {
    color: #4160ac;
    color: #4160ac;
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer;
  }
  .share__class__span {
    margin-right: 70px;
  }
`;

const NoDataWrap = styled.div`
  width: 100%;
  padding: 100px 100px;
  svg {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  h1 {
    dispaly: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 545px) {
    padding: 20px 20px;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 50px !important;
  border: 1px solid #ededed !important;
  color: white;
  font-size: 18px;
  background: ${({ bgColor }) => bgColor || "#ededed"};
  border-radius: 5px;
  text-transform: uppercase;

  .anticon {
    transform: rotate(180deg) !important;
  }
  :disabled,
  [disabled]:hover,
  :hover,
  :active,
  :focus {
    background: ${({ bgColor }) => bgColor || "#aaaaaa"}90 !important;
    color: #ffffff;
  }
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    color: #ffffff;
  }

  @media (max-width: 1550px) {
    height: 40px !important;
    font-size: 14px;

    span {
      font-size: 14px;
      line-height: 15px;
    }
  }
`;

const ApplicationProccess = styled.div`
  padding: 10px;
  width: 100%;
  overflow: auto;
  white-space: nowrap;

  .application__width {
    min-width: 230px;
  }
  .approval__width {
    min-width: 175px;
  }
  .lease__width {
    min-width: 280px;
  }
  .signed__width {
    min-width: 190px;
  }

  .ant-divider-horizontal {
    ${"" /* width: 41px; */}
    min-width: 10px;
    border: 1px solid #dddddd;
    height: 0px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .ant-row {
    align-items: center;
    flex-flow: nowrap;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #dee8ff;
    border-color: #dee8ff;
  }

  .anticon {
    color: #4160ac;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #4160ac;
    font-weight: 400;
    font-size: 16px;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #dee8ff;
    border-color: #dee8ff;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    color: #4160ac;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #eee;
    border-color: #eee;
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #2b353f;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #2b353f;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #ddd;
  }

  .ant-steps-vertical {
    display: flex;
    flex-direction: row;
  }
  .ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after,
  .ant-steps-item-tail {
    display: none !important;
  }

  .anticon svg {
    width: 13px;
  }

  ::-webkit-scrollbar,
  .ant-modal-wrap::-webkit-scrollbar {
    width: 0;
    display: none;
    @media (max-width: 767px) {
      width: 0 !important;
      display: none !important;
    }
  }

  @media (max-width: 1550px) {
    padding: 10px;

    .application__width {
      min-width: 184px;
    }
    .approval__width {
      min-width: 140px;
    }
    .lease__width {
      min-width: 224px;
    }
    .signed__width {
      min-width: 152px;
    }

    .ant-divider-horizontal {
      width: 32px;
      min-width: 8px;
    }

    span {
      font-size: 12px;
      line-height: 15px;
    }

    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      font-size: 12px;
    }

    .anticon svg {
      width: 10px;
    }
  }

  @media (max-width: 991.98px) {
    .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
      min-height: auto;
    }
  }
`;

export const DetailWrap = styled.div`
  width: 100%;

  h1 {
    color: #2b353f;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  h2 {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  h3 {
    color: #2b353f;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    cursor: pointer;
    padding-left: 15px;
  }

  .button__group {
    width: 100%;
    dispaly: flex;
    margin-top: 40px;
  }
  .nextback__icon {
    cursor: pointer;
  }

  @media (max-width: 991px) {
    h1 {
      font-size: 22px;
    }
    h3 {
      width: 100%;
    }
  }
`;
