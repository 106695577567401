import {
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  List,
  Menu,
  Row,
  Skeleton,
  message,
} from "antd";
import { LessSvg, PlusSvg, UploadFileSvg } from "assets/svgs";
import { StatusButton } from "pages/profile-verification/Paystubs";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import { ContainerWrap } from "ui/Layout/ContainerWrap";
import { DetailPageWrap, UploadBox, UploadInput } from "./PayStubsDetailsPage";
import {
  AddedReference,
  DetailsBox,
  ViewAddedReference,
  ViewAllRental,
} from "components/tenants/EmployerDetails";
import {
  dateConverter,
  formatPhoneNumber,
  upperCase,
  utcToLocal,
} from "pages/utils/fn";
import {
  addLog,
  addNote,
  getHistory,
  getEmployerDetail,
  verifyTenantData,
} from "store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { StyledUploadPreviewWrapper } from "./RentalHistoryDetailPage";
import { ImagePreview } from "components/imagePreview";

const EmployerDetailPage = () => {
  const [empObj, setEmpObj] = useState({});
  const [newSetalFields, setNewSetalFields] = useState([]);
  const [image, setImage] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [callBtnLoading, setCallBtnLoading] = useState(false);
  const [ticketBtnLoading, setTicketBtnLoading] = useState(false);
  const [approveBtnLoading, setApproveBtnLoading] = useState(false);
  const [denyBtnLoading, setDenyBtnLoading] = useState(false);
  const [isEmploymentVerified, setIsEmploymentVerified] = useState();
  const [openIndexs, setOpenIndexs] = useState([]);
  const [historyObj, setHistoryObj] = useState({});
  const [historyArr, setHistoryArr] = useState([]);
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const upload = React.useRef(null);
  const drop = React.useRef(null);

  const userId = localStorage.getItem("prospect_userId");
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    setLoadings(true);
    dispatch(getEmployerDetail()).then(({ payload }) => {
      setIsEmploymentVerified(payload?.status);
      setEmpObj(payload);
      setLoadings(false);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(empObj || {}).length) {
      setNewSetalFields(empObj?.data);
    }
  }, [empObj]);

  useEffect(() => {
    if (historyObj && Object.keys(historyObj).length) {
      let arr = Object.values(historyObj);
      setHistoryArr(arr);
    }
  }, [historyObj]);

  const openIndexFun = (idx) => {
    if (openIndexs?.includes(idx)) {
      var filteredArray = openIndexs?.filter((e) => {
        return e !== idx;
      });
      setOpenIndexs(filteredArray);
    } else {
      setOpenIndexs((val) => [...val, idx]);
    }
  };

  const approveEmployement = () => {
    setApproveBtnLoading(true);
    let data = {};
    data.user_id = userId;
    data.admin_id = user?.id;
    data.document_name = "employer_details";
    data.status = 1;
    dispatch(verifyTenantData(data)).then(({ payload }) => {
      setApproveBtnLoading(false);
      if (payload) {
        getHistoryList();
        setIsEmploymentVerified(payload?.is_employment_verified);
        message.info(`${upperCase(empObj?.name)}'s Employement Approved `);
      }
    });
  };

  const denyEmployement = (e) => {
    setDenyBtnLoading(true);
    let data = {};
    data.user_id = userId;
    data.admin_id = user?.id;
    data.document_name = "employer_details";
    data.employment_reject_reason = e.key;
    data.status = 2;
    dispatch(verifyTenantData(data)).then(({ payload }) => {
      setDenyBtnLoading(false);
      if (payload) {
        getHistoryList();
        setIsEmploymentVerified(payload?.is_employment_verified);
        message.info(`${upperCase(empObj?.name)}'s Employement Denied `);
      }
    });
  };

  const addLogAction = (logType) => {
    let data = {};
    if (logType === "call") {
      setCallBtnLoading(true);
      data.userid = userId;
      data.admin_id = user?.id;
      data.type = "called";
      data.contant_info = empObj?.phone_no;
      data.document_type = "employer_details";
      dispatch(addLog(data)).then(({ payload }) => {
        setCallBtnLoading(false);
        getHistoryList();
      });
    } else {
      setTicketBtnLoading(true);
      data.userid = userId;
      data.admin_id = user?.id;
      data.type = "opened_ticket";
      data.contant_info = empObj?.email;
      data.document_type = "employer_details";
      dispatch(addLog(data)).then(({ payload }) => {
        setTicketBtnLoading(false);
        getHistoryList();
      });
    }
  };

  const items = (
    <Menu onClick={(e) => denyEmployement(e)}>
      <Menu.Item key="suspicious">
        <span>Suspicious</span>
      </Menu.Item>
      <Menu.Item key="fraud">
        <span>Fraud</span>
      </Menu.Item>
      <Menu.Item key="inclusive">
        <span>Inclusive</span>
      </Menu.Item>
      <Menu.Item key="other">
        <span>Other</span>
      </Menu.Item>
    </Menu>
  );

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
  }, []);

  const fileChangeHandler = async (event) => {
    setImage([]);
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setImage((s) => [
        ...s,
        {
          file: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
          ext: img.type,
        },
      ]);
    };

    Array(...event.target.files) &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          let fileExtension = [
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
            "jfif",
            "tiff",
          ];
          let filename = file.name;
          const fileExt = filename
            .substring(filename.lastIndexOf(".") + 1)
            .toLowerCase();
          if (fileExtension.includes(fileExt)) {
            return await newFun(file);
          } else {
            return message.warning("Please upload Image file");
          }
        })
      ));
  };

  const deleteImgInput = () => {
    const inputImg = document.getElementById("myInput");
    inputImg.value = null;
  };

  const onFinish = (data) => {
    setLoadings(true);
    data.userid = userId;
    data.admin_id = user?.id;
    data.note = data.note;
    if (image?.length) {
      data.attachment = image[0];
    } else {
      data.attachment = {};
    }
    data.document_type = "employer_details";
    dispatch(addNote(data)).then(({ payload }) => {
      setLoadings(false);
      if (payload) {
        getHistoryList();
        message.success("Form submitted Successfully");
      }
    });
    setImage([]);
    form.resetFields();
  };

  const attachmentLink = (item) => {
    let str = item?.note;
    if (str === null || str === "") return false;
    else str = str.toString();
    let text = str.replace(/(<([^>]+)>)/gi, "");
    return (
      <>
        <Row>
          <Col span={24}>{text}</Col>
          <Col span={24}>
            {item?.note_attachment_URL ? (
              <a href={item?.note_attachment_URL} target="blank">
                Attachment Link
              </a>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </>
    );
  };

  const getHistoryList = () => {
    let data = {};
    data.document_type = "employer_details";
    data.userid = userId;
    dispatch(getHistory(data)).then(({ payload }) => {
      setHistoryObj(payload);
    });
  };
  return (
    <Container paddingSm="40px 40px 0 265px">
      <Row gutter={[20, 20]} justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Heading>
            <Link to="">Employer Detail</Link>
          </Heading>
        </Col>
      </Row>
      <ContainerWrap brRadius="5px" bgcolor="#ffffff" paddings="10px">
        <DetailPageWrap>
          <Row justify="space-between" className="header__status">
            <Col>
              <h2>{upperCase(empObj?.name)}</h2>
            </Col>
            <Col>
              <h4>
                {isEmploymentVerified === 0
                  ? "Pending"
                  : isEmploymentVerified === 1
                  ? "Approved"
                  : "Denied"}
              </h4>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[20, 0]} style={{ padding: "10px" }}>
            {!newSetalFields?.length && (
              <Skeleton paragragh={true} title={true}></Skeleton>
            )}
            {newSetalFields?.map(
              (
                {
                  address,
                  city,
                  businessName,
                  grossMonthlyIncome,
                  position,
                  state,
                  zipcode,
                  supervisorsName,
                  supervisorsNumber,
                  workPhone,
                  dateStarted,
                },
                idx
              ) => (
                <>
                  <ViewAllRental
                    mb={openIndexs?.includes(idx) ? "0" : "25px"}
                    key={idx}
                  >
                    <AddedReference
                      bb={
                        openIndexs?.includes(idx) ? "none" : "1px solid #dddddd"
                      }
                      onClick={() => openIndexFun(idx)}
                    >
                      <Row
                        justify="space-between"
                        style={{ alignItems: "center" }}
                      >
                        <Col>
                          <Row gutter={[20, 20]}>
                            <Col>
                              <span>{businessName}</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          {openIndexs?.includes(idx) ? (
                            <>
                              <LessSvg
                                style={{ marginBottom: "5px" }}
                                className="less__svg"
                              />
                            </>
                          ) : (
                            <>
                              <PlusSvg />
                            </>
                          )}
                        </Col>
                      </Row>
                    </AddedReference>
                  </ViewAllRental>

                  {openIndexs?.includes(idx) ? (
                    <ViewAddedReference>
                      <Row justify="space-between" gutter={[15, 15]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                          <h6>Address</h6>
                          <Row justify="space-between" gutter={[2, 2]}>
                            <Col span={8}>
                              <DetailsBox>
                                <p>Address</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{address}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>City</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{city}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>State</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{state}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Zipcode</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{zipcode ? zipcode : `${" "}`}</p>
                              </DetailsBox>
                            </Col>
                            <Col span={8}>
                              <DetailsBox>
                                <p>Position</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{position}</p>
                              </DetailsBox>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                          <h6>Employement Details</h6>
                          <Row justify="space-between" gutter={[2, 2]}>
                            <Col span={8}>
                              <DetailsBox>
                                <p>Work Phone</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{workPhone}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Gross Income</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{grossMonthlyIncome}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Date Started</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{dateConverter(dateStarted)}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Supervisor Name</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{supervisorsName}</p>
                              </DetailsBox>
                            </Col>
                            <Col span={8}>
                              <DetailsBox>
                                <p>Supervisor Number</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{formatPhoneNumber(supervisorsNumber)}</p>
                              </DetailsBox>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </ViewAddedReference>
                  ) : (
                    ""
                  )}
                </>
              )
            )}
          </Row>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Row>
                <Form form={form} onFinish={onFinish}>
                  <Form.Item
                    name="note"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Your Message is required",
                      },
                    ]}
                  >
                    <Col span={24}>
                      <TextArea rows={5} placeholder="Type here" />
                    </Col>
                  </Form.Item>
                  <Form.Item name="attachment">
                    <Row>
                      <Col>
                        <StyledUploadPreviewWrapper isPhoto={image.length}>
                          <ImagePreview
                            key={""}
                            src={image.length ? image[0].path : ""}
                            isCoverDisProp={false}
                            height={95}
                            width={95}
                            onDelete={() => {
                              const st = [];
                              setImage(st);
                              deleteImgInput();
                              upload.current.value = null;
                            }}
                          />
                        </StyledUploadPreviewWrapper>
                      </Col>
                    </Row>
                    <Col span={24}>
                      <UploadBox
                        ref={drop}
                        onClick={(e) => upload.current.click()}
                      >
                        <UploadInput>
                          <p className="ant-upload-drag-icon">
                            <UploadFileSvg className="upload__icon" />
                          </p>
                          <p className="upload__icon">Upload</p>
                        </UploadInput>
                      </UploadBox>
                      <input
                        accept="image/*"
                        id="myInput"
                        multiple={"multiple"}
                        type="file"
                        ref={upload}
                        style={{ display: "none" }}
                        onChange={fileChangeHandler}
                      />
                    </Col>
                  </Form.Item>
                  <Form.Item>
                    <Row className="save__btn" justify="end">
                      <Col>
                        <StatusButton bgColor="#F67C36" htmlType="submit">
                          Save
                        </StatusButton>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <List
                header={<h2>History</h2>}
                footer={null}
                bordered
                loading={loadings}
                dataSource={historyArr?.length ? historyArr : empObj?.history}
                renderItem={(item) => (
                  <List.Item extra={utcToLocal(item?.logtime)}>
                    <Skeleton
                      title
                      paragraph={{ rows: 1 }}
                      loading={loadings}
                      active
                    >
                      <List.Item.Meta description={attachmentLink(item)} />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
          <Divider />
          <Row gutter={[10, 10]} justify="end">
            <Col xs={24} sm={24} md={6} lg={6} xl={3} xxl={3}>
              <StatusButton
                loading={callBtnLoading}
                bgColor="#58D255"
                onClick={() => addLogAction("call")}
              >
                <a href="tel:+14086343220">Call</a>
              </StatusButton>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={3} xxl={3}>
              <StatusButton
                loading={approveBtnLoading}
                bgColor="#F67C36"
                onClick={() => approveEmployement()}
                disabled={isEmploymentVerified === 1}
              >
                Approve
              </StatusButton>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={3} xxl={3}>
              <Dropdown
                disabled={isEmploymentVerified === 2}
                trigger="click"
                overlay={items}
                placement="top"
              >
                <StatusButton loading={denyBtnLoading} bgColor="#2B353F">
                  <span>Deny</span>
                </StatusButton>
              </Dropdown>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={3} xxl={3}>
              <StatusButton
                loading={ticketBtnLoading}
                bgColor="#4160AC"
                onClick={() => addLogAction()}
              >
                <a href="mailto:someone@gmail.com">Open Ticket</a>
              </StatusButton>
            </Col>
          </Row>
        </DetailPageWrap>
      </ContainerWrap>
    </Container>
  );
};

export default EmployerDetailPage;
