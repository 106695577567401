import React, { useCallback, useEffect, useState } from "react";
import { Container } from "ui/Layout/Container";
import { Col, Input, Progress, Row, Table, message } from "antd";
import { Heading } from "ui/Heading";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useWindowSize } from "pages/utils/hooks/useWindowSize";
import TenantDetailModal from "../../components/tenants/TenantDetailModal";
import {
  Capitalize,
  _merge,
  decimalRemove,
  formatPhoneNumber,
} from "pages/utils/fn";
import { httpAPI } from "api";
import { MdCancel } from "react-icons/md";
import useDebounce from "pages/utils/hooks/useDebounce";

const Applications = () => {
  const { width } = useWindowSize();
  const [yScroll, setyScroll] = useState(450);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [selectedApplication, setSelectedAppliction] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [userName, setUserName] = useState("");
  const [searchData, setSearchData] = useState([]);
  const Search = Input.Search;
  const [searchBarText, setSearchBarText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    size: "small",
    query: `${encodeURIComponent(searchBarText)}`,
    showSizeChanger: true,
  });

  const emitEmpty = () => {
    setSearchBarText("");
  };

  const suffix = <MdCancel onClick={() => emitEmpty()} />;
  const deBounceSearchBarText = useDebounce(searchBarText.trim(), 1000);

  useEffect(() => {
    if (deBounceSearchBarText?.length >= 3) {
      setLoading(true);
      httpAPI(
        `/all-users`,
        {
          pageSize: 10,
          pageNumber: 1,
          query: `${encodeURIComponent(deBounceSearchBarText)}`,
        },
        "POST"
      ).then((res) => {
        setPagination((p) => ({ ...p, total: res?.data?.total }));
        setSearchData(res?.data?.Data);
        setLoading(false);
      });
    } else {
      let newPagination = {
        ...pagination,
      };
      getTenants(newPagination, searchBarText);
    }
  }, [deBounceSearchBarText]);

  const getTenants = useCallback(
    (pagination, search = "") => {
      setLoading(true);
      httpAPI(
        `/all-users`,
        { ...pagination, query: encodeURIComponent(search) },
        "POST"
      )
        .then((payload) => {
          if (payload?.data) {
            setSearchData(payload?.data?.Data);
            setPagination((p) => ({ ...p, total: payload?.data?.total }));
          } else {
            setSearchData([]);
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error) {
            message.error(error.message);
          }
        })
        .finally(() => {});
    },
    [pagination]
  );

  useEffect(() => {
    if (width < 1550) {
      setyScroll(450);
    } else {
      setyScroll(600);
    }
  }, [width, location.pathname]);

  const columns = [
    {
      title: "Tenant Name",
      dataIndex: "firstName",
      render: (_, record) => (
        <div>
          <td>
            {Capitalize(record.firstName)} {Capitalize(record.lastName)}
          </td>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      render: (record) => formatPhoneNumber(record),
    },
    {
      title: "City",
      dataIndex: "unit_id",
      render: (_, record) => <>{record?.general_profile?.city}</>,
    },
    {
      title: "State",
      dataIndex: "unit_id",
      render: (_, record) => <>{record?.general_profile?.state}</>,
    },
    {
      title: "Zip Code",
      dataIndex: "unit_id",
      render: (_, record) => <>{record?.general_profile?.zipcode}</>,
    },
    {
      title: "Status",
      dataIndex: "profile_percentage",
      filters: [
        // {
        //   text: "Approved",
        //   value: "100%",
        // },
        // {
        //   text: "83.35%",
        //   value: "83.35%",
        // },
        // {
        //   text: "66.68%",
        //   value: "66.68%",
        // },
        // {
        //   text: "50.01%",
        //   value: "50.01%",
        // },
        // {
        //   text: "33.34%",
        //   value: "33.34%",
        // },
        // {
        //   text: "16.67%",
        //   value: "16.67%",
        // },
        // {
        //   text: "0%",
        //   value: "0%",
        // },
        {
          text: "Approved",
          value: 1,
        },
        {
          text: "Pending",
          value: 0,
        },
      ],
      onFilter: (value, record) => record?.is_verified === value,
      render: (_, record) => (
        <>
          {record?.is_verified === 10 ? (
            <span style={{ color: "#56C274" }}>Approved</span>
          ) : (
            <Progress
              type="circle"
              width="25px"
              percent={decimalRemove(record?.profile_percentage)}
            />
          )}
        </>
      ),
    },
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    setLoading(true);
    newPagination.pageNumber = newPagination.current;
    getTenants(newPagination, searchBarText);
    setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
  };
  useEffect(() => {
    if (selectedApplication && Object.keys(selectedApplication).length) {
      localStorage.setItem("app_id", selectedApplication?.id);
    }
  }, [selectedApplication]);

  const updatedData = (item) => {
    const arr = [];
    searchData?.map((val) => {
      if (val.id === item.user_id) {
        val.profile_percentage = item.profile_percentage;
        arr.push(val);
      } else {
        arr.push(val);
      }
    });
    setSearchData(arr);
  };

  const onSearch = (e) => {
    setSearchBarText(e.target.value);
  };

  return (
    <Container paddingSm="40px 40px 0 265px">
      <Row gutter={[20, 20]} justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Heading>
            <Link to="/tenants">Tenants</Link>
          </Heading>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={8}
          className="search__filter"
        >
          <Search
            ref={(ele) => searchBarText === ele}
            suffix={suffix}
            placeholder="Search..."
            value={searchBarText}
            onChange={onSearch}
            onPressEnter={onSearch}
          />
        </Col>
      </Row>
      <DiscountTable>
        <Table
          className="app__table"
          columns={columns}
          dataSource={[...searchData]}
          loading={loading}
          onChange={handleTableChange}
          pagination={pagination}
          onRow={(item) => ({
            onClick: () => {
              setSelectedAppliction(item);
              setUserName(`${item?.firstName} ${item?.lastName} `);
              setModalState(true);
            },
          })}
          scroll={{ x: 1300, y: 550 }}
        />
      </DiscountTable>
      <TenantDetailModal
        modalState={modalState}
        userName={userName}
        updatedData={updatedData}
        onCancel={(value) => setModalState(value)}
      />
    </Container>
  );
};

export default Applications;

export const DiscountTable = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  cursor: pointer;

  .ant-progress {
    font-size: 10px;
  }

  .ant-pagination.mini .ant-pagination-options-size-changer {
    margin-right: 20px;
  }
  .ant-select-selection-placeholder {
    color: #2b353f !important;
  }
  :hover {
    border-color: #aaaaaa !important;
  }
  .ant-select-selector {
    position: relative;
    background-color: #eee;
    border: none !important;
    border-radius: 5px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 40px !important;
    align-items: center;
    margin-right: 20px;
  }
  .ant-select-selector {
    border-color: #aaaaaa !important;
    background-color: #eeeeee !important;
    box-shadow: none !important;
  }
  @media (max-width: 1550px) {
    .ant-select-selection-placeholder {
      font-size: 13px;
    }
    .ant-select-selector {
      height: 40px !important;
    }
  }

  .ant-table-body {
    overflow: auto scroll;
    max-height: 550px;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #dee8ff;
  }

  .ant-table-body {
    /* scroll bar */
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;

      @media (max-width: 767px) {
        width: 0 !important;
        display: none !important;
      }
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

      @media (max-width: 767px) {
        box-shadow: none;
      }
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      outline: 1px solid #c4c4c4;
      border-radius: 10px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .ant-table-container,
  .ant-table-content,
  table {
    border-radius: 5px 5px 0 0 !important;
  }

  .ant-table-container .ant-table-content table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  }

  .ant-table-container,
  .ant-table-header,
  table {
    border-radius: 5px 5px 0 0 !important;
  }
  ${
    "" /* .ant-table-container .ant-table-body table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  } */
  }

  .ant-table-tbody > tr > td {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.forth};
  }

  .ant-table-thead > tr > th {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    background-color: ${({ theme }) => theme.colors.forth} !important;
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    display: flex;
  }

  /* FOR PAGINATION */
  .ant-table-pagination.ant-pagination {
    margin: 30px 0;
  }
  ${
    "" /* .ant-pagination.mini .ant-pagination-options {
    display: none;
  } */
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.forth};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }

  @media (max-width: 1550px) {
    .ant-table.ant-table-middle {
      font-size: 14px;
    }

    .ant-table.ant-table-middle .ant-table-thead > tr > th {
      padding: 10px 6px;
    }

    .ant-table-thead > tr > th {
      font-weight: 500;
      font-size: 14px;
      line-height: 12px;
      padding: 12px 12px;
    }

    .ant-table-tbody > tr > td {
      font-size: 14px;
      line-height: 16px;
    }

    /* FOR PAGINATION */
    .ant-table-pagination.ant-pagination {
      margin: 24px 0;
    }
    ${
      "" /* .ant-pagination.mini .ant-pagination-options {
      display: none;
    } */
    }
    .ant-pagination {
      font-size: 16px;
      line-height: 20px;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      font-size: 16px;
      padding-right: 20px;
    }
    .ant-pagination.mini .ant-pagination-item {
      margin-right: 16px;
      min-width: 32px;
      height: 32px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 26px;
    }
    .ant-pagination-item a {
      padding: 5px 4px;
    }
  }

  @media (max-width: 991.98px) {
    margin-top: 10px;
  }
  @media (max-width: 425px) {
    .ant-pagination.mini .ant-pagination-item {
      min-width: 22px;
      margin-right: 10px;
      height: 23px;
    }
    .ant-pagination-prev .ant-pagination-item-link {
      padding-right: 10px;
    }
    .ant-pagination.mini .ant-pagination-item {
      line-height: 5px;
    }
    .ant-pagination-item a {
      padding: 9px 0px;
    }
  }
`;
