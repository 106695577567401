import {
  Button,
  Col,
  Dropdown,
  Image,
  Input,
  Menu,
  Row,
  Table,
  message,
} from "antd";
import { httpAPIInviteSND } from "api";
import { DiscountTable } from "pages/Applications/Applications";
import { Capitalize, upperCase } from "pages/utils/fn";
import useDebounce from "pages/utils/hooks/useDebounce";
import React, { useCallback, useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import { statusFontColor } from "./EmployerDetail";
import { verifyTenantData } from "store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";

const Paystubs = () => {
  const [searchBarText, setSearchBarText] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [approveLoading, setApproveLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);
  const [indexVal, setIndexVal] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    size: "small",
    showSizeChanger: true,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Search = Input.Search;
  const { user } = useSelector((state) => state.user);

  const deBounceSearchBarText = useDebounce(searchBarText.trim(), 1000);

  // Calling Api with or without search text for table data
  useEffect(() => {
    if (deBounceSearchBarText?.length >= 3) {
      setLoading(true);
      httpAPIInviteSND(
        `/profile-verification-data`,
        {
          pageSize: 10,
          pageNumber: 1,
          type: "pay_stubs",
          query: `${encodeURIComponent(deBounceSearchBarText)}`,
        },
        "POST"
      ).then((res) => {
        setPagination((p) => ({ ...p, total: res?.data?.total }));
        setSearchData(res?.data?.Data);
        setLoading(false);
      });
    } else {
      let newPagination = {
        ...pagination,
      };
      getPayStubList(newPagination, searchBarText);
    }
  }, [deBounceSearchBarText]);

  // Function to call API for table data
  const getPayStubList = useCallback(
    (pagination, search = "") => {
      setLoading(true);
      httpAPIInviteSND(
        `/profile-verification-data`,
        { ...pagination, type: "pay_stubs", query: encodeURIComponent(search) },
        "POST"
      )
        .then((payload) => {
          if (payload?.data) {
            setSearchData(payload?.data?.Data);
            setPagination((p) => ({ ...p, total: payload?.data?.total }));
          } else {
            setSearchData([]);
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error) {
            message.error(error.message);
          }
        })
        .finally(() => {});
    },
    [pagination]
  );

  // To set search bar text
  const onSearch = (e) => {
    setSearchBarText(e.target.value);
  };

  // To call API when onchange event ocuur in table
  const handleTableChange = (newPagination, filters, sorter) => {
    setLoading(true);
    newPagination.pageNumber = newPagination.current;
    getPayStubList(newPagination, searchBarText);
    setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
  };

  // To empty search bar text
  const emitEmpty = () => {
    setSearchBarText("");
  };

  // Function to call API to approve Paystub
  const approvePaystub = (item) => {
    setApproveLoading(true);
    let data = {};
    if (item?.type === "pay_stub1") {
      data.document_name = "pay_stub1";
      data.user_id = item?.id;
      data.status = 1;
      data.admin_id = user?.id;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setApproveLoading(false);
        if (payload) {
          message.info(
            `${upperCase(item?.firstName)}'s PayStub Detail Approved `
          );
          let newPagination = {
            ...pagination,
          };
          getPayStubList(newPagination, searchBarText);
        }
      });
    } else {
      data.document_name = "pay_stub2";
      data.user_id = item?.id;
      data.status = 1;
      data.admin_id = user?.id;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setApproveLoading(false);
        if (payload) {
          message.info(
            `${upperCase(item?.firstName)}'s Paystub Detail Approved `
          );
          let newPagination = {
            ...pagination,
          };
          getPayStubList(newPagination, searchBarText);
        }
      });
    }
  };

  // Function to call API to deny Paystub
  const denyPaystub = (e, item) => {
    setDenyLoading(true);
    let data = {};
    if (item?.type === "pay_stub1") {
      data.document_name = "pay_stub1";
      data.user_id = item?.id;
      data.status = 2;
      data.admin_id = user?.id;
      data.paystub1_reject_reason = e.key;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setDenyLoading(false);
        if (payload) {
          message.info(
            `${upperCase(item?.firstName)}'s Paystub Detail Denied `
          );
          let newPagination = {
            ...pagination,
          };
          getPayStubList(newPagination, searchBarText);
        }
      });
    } else {
      data.document_name = "pay_stub2";
      data.user_id = item?.id;
      data.status = 2;
      data.admin_id = user?.id;
      data.paystub2_reject_reason = e.key;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setDenyLoading(false);
        if (payload) {
          message.info(
            `${upperCase(item?.firstName)}'s Paystub Detail denied `
          );
          let newPagination = {
            ...pagination,
          };
          getPayStubList(newPagination, searchBarText);
        }
      });
    }
  };

  const suffix = <MdCancel onClick={() => emitEmpty()} />;

  // columns details for table
  const columns = [
    {
      title: "Paystubs",
      dataIndex: "",
      // width: "25%",
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
          },
        };
      },
      render: (_, record) => {
        return (
          <>
            <Row gutter={[10, 0]}>
              <Col>
                <Image
                  width={100}
                  height={100}
                  src={
                    record?.actualJSON?.payStub1?.path
                      ? record?.actualJSON?.payStub1?.path
                      : ""
                  }
                />
              </Col>
              <Col>
                <Image
                  width={100}
                  height={100}
                  src={
                    record?.actualJSON?.payStub2?.path
                      ? record?.actualJSON?.payStub2?.path
                      : ""
                  }
                />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "firstName",
      // width: "25%",
      render: (_, record) => {
        return (
          <div>
            <td>
              {Capitalize(record.firstName)} {Capitalize(record.lastName)}
            </td>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      // width: "25%",
      render: (_, record) => {
        return (
          <div
            style={{
              color: statusFontColor(
                record?.status === 0
                  ? "Pending"
                  : record?.status === 1
                  ? "Approved"
                  : "Denied"
              ),
            }}
          >
            <Row gutter={[10, 10]}>
              {" "}
              <Col>
                <span>
                  {record?.type === "pay_stub1" ? "Pay Stub 1" : "Pay Stub 2"} :
                </span>
              </Col>
              <Col>
                <span>
                  {record?.status === 0
                    ? "Pending"
                    : record?.status === 1
                    ? "Approved"
                    : "Denied"}
                </span>
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "action",
      // width: "25%",
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
          },
        };
      },
      render: (_, record, index) => {
        return (
          <>
            {record?.status === 0 ? (
              <Row gutter={[10, 10]}>
                <Col>
                  <Dropdown
                    trigger="click"
                    overlay={() => items(record, index)}
                    placement="top"
                  >
                    <StatusButton
                      loading={indexVal === index && denyLoading}
                      bgColor="#2B353F"
                    >
                      Deny
                    </StatusButton>
                  </Dropdown>
                </Col>
                <Col>
                  <StatusButton
                    loading={indexVal === index && approveLoading}
                    bgColor="#F67C36"
                    onClick={() => {
                      approvePaystub(record);
                      setIndexVal(index);
                    }}
                  >
                    Approve
                  </StatusButton>
                </Col>
              </Row>
            ) : record?.status === 1 ? (
              <Row gutter={[10, 10]}>
                <Col>
                  <Dropdown
                    trigger="click"
                    overlay={() => items(record, index)}
                    placement="top"
                  >
                    <StatusButton
                      loading={indexVal === index && denyLoading}
                      bgColor="#2B353F"
                    >
                      Deny
                    </StatusButton>
                  </Dropdown>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <StatusButton
                    loading={indexVal === index && approveLoading}
                    bgColor="#F67C36"
                    onClick={() => {
                      approvePaystub(record);
                      setIndexVal(index);
                    }}
                  >
                    Approve
                  </StatusButton>
                </Col>
              </Row>
            )}
          </>
        );
      },
    },
  ];

  // Dropdown options for deny button
  const items = (val, idx) => {
    setIndexVal(idx);
    return (
      <Menu onClick={(e) => denyPaystub(e, val)}>
        <Menu.Item key="suspicious">
          <h3>Suspicious</h3>
        </Menu.Item>
        <Menu.Item key="fraud">
          <h3>Fraud</h3>
        </Menu.Item>
        <Menu.Item key="inclusive">
          <h3>Inclusive</h3>
        </Menu.Item>
        <Menu.Item key="other">
          <h3>Other</h3>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Container paddingSm="40px 40px 0 265px">
      <Row gutter={[20, 20]} justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Heading>
            <Link to="/paystubs">Paystubs</Link>
          </Heading>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={8}
          className="search__filter"
        >
          <Search
            ref={(ele) => searchBarText === ele}
            suffix={suffix}
            placeholder="Search..."
            value={searchBarText}
            onChange={onSearch}
            onPressEnter={onSearch}
          />
        </Col>
      </Row>
      <DiscountTable>
        <Table
          className="app__table"
          columns={columns}
          dataSource={[...searchData]}
          loading={loading}
          onChange={handleTableChange}
          pagination={pagination}
          rowKey={(record, index) => index}
          onRow={(item) => ({
            onClick: () => {
              localStorage.setItem("prospect_userId", item?.id);
              localStorage.setItem("payStub_type", item?.type);
              navigate(`/paystubs-detail/${item?.id}`);
            },
          })}
          scroll={{ x: 1300, y: 550 }}
        />
      </DiscountTable>
    </Container>
  );
};

export default Paystubs;

export const StatusButton = styled(Button)`
  width: 100%;
  height: 40px;
  border: 1px solid #ededed !important;
  color: white;
  font-size: 18px;
  background: ${({ bgColor }) => bgColor || "#ededed"};
  border-radius: 5px;
  text-transform: uppercase;

  :disabled,
  :disabled:hover {
    background: ${({ bgColor }) => bgColor || "#aaaaaa"}90;
  }

  .anticon {
    transform: rotate(180deg) !important;
  }
  :hover,
  :active,
  :focus {
    background: ${({ bgColor }) => bgColor || "#aaaaaa"}90;
    color: #ffffff;
  }
  span {
    color: #ffffff;
  }
  .ant-btn-loading-icon {
    margin-right: 5px;
  }

  @media (max-width: 1550px) {
    height: 40px !important;
    font-size: 14px;

    span {
      font-size: 14px;
      line-height: 15px;
    }
  }
`;
