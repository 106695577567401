import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import MyAccountSidebar from "./MyAccountSidebar";
import { useWindowSize } from "pages/utils/hooks/useWindowSize";

function MyAccountLayout({ isEdit, isEditTab, imgState, onTabChangeFun }) {
  const [minHeight, setMinHeight] = useState("calc(100vh - 80px)");
  const { width } = useWindowSize();
  const location = useLocation();

  // To set min heigth w.r.t screen width
  useEffect(() => {
    if (width < 1550 && width > 991) {
      setMinHeight("calc(100vh - 320px)");
    } else {
      setMinHeight("440px");
    }
  }, [width, location.pathname]);
  return (
    <MyAccountLayoutWrap minHeight={minHeight}>
      <MyAccountSidebar onTabChangeFun={onTabChangeFun} isEdit={isEdit} imgState={imgState} isEditTab={isEditTab} />
    </MyAccountLayoutWrap>
  );
}

export default MyAccountLayout;

export const MyAccountLayoutWrap = styled.div`
  background: #ffffff;
  min-height: 440px;
  border-radius: 0 0 5px 5px;

  @media (max-width: 1550px) {
    min-height: calc(100vh - 420px);
  }
`;
