import { Button, Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { me } from "../../store/reducers/user.reducer";
import { useState } from "react";

function PersonalDetails({ isEdit }) {
  // const { FirstName, LastName, PhoneNumber, Email } = useSelector(
  //   (state) => state.personalDetails.userPersonalDetails
  // );

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  // useEffect(() => {
  //   dispatch(me());
  // }, [dispatch]);

  return (
    <PersonalDetailsWrap>
      <Row gutter={[15, 15]} justify="space-between">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>First Name</span>
          <h2>
            {user?.first_name
              ? user?.first_name?.charAt(0).toUpperCase() +
                user?.first_name?.slice(1)
              : "-"}
          </h2>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>Last Name</span>
          <h2>
            {user?.last_name
              ? user?.last_name?.charAt(0).toUpperCase() +
                user?.last_name?.slice(1)
              : "-"}
          </h2>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>Contact Number</span>
          <h2>{user?.phone_no ? user?.phone_no : "-"}</h2>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>Email</span>
          <h2>{user?.email_id}</h2>
        </Col>
      </Row>
      <LeftBottomEdit>
        <Row>
          <Col>
            <AddNewApr hg="40px" onClick={isEdit}>
              Edit
            </AddNewApr>
          </Col>
        </Row>
      </LeftBottomEdit>
    </PersonalDetailsWrap>
  );
}

export default PersonalDetails;

export const AddNewApr = styled(Button)`
  ${"" /* width: ${({ wh }) => wh || "100%"}; */}
  padding: 0 15px;
  height: ${({ hg }) => hg || "40px"};
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: ${({ theme }) => theme.colors.third};
  color: #ffffff;
  ${"" /* text-transform: uppercase; */}

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.third}90;
    background: ${({ theme }) => theme.colors.third}90;
  }

  span {
    font-weight: ${({ fw }) => fw || "400"};
    font-size: ${({ fs }) => fs || "18px"};
  }

  @media (max-width: 1550px) {
    span {
      font-size: 14px;
    }
  }
  @media (max-width: 991.98px) {
    display: ${({ displayView }) => displayView || "block"};
  }
`;

export const LeftBottomEdit = styled.div`
  span {
    color: #ffffff !important;
  }

  padding: 30px 0;
  width: 100%;
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.forth};
  }

  @media (max-width: 1550px) {
    padding: 24px 0;

    h2 {
      font-size: 20px;
      line-height: 23px;
    }
  }
  @media (max-width: 420px) {
    padding: 10px 0;
  }
`;

export const PersonalDetailsWrap = styled.div`
  ${"" /* padding: 0 60px; */}
  @media (max-width: 1441px) {
    padding: 0;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #aaaaaa;
  }
  h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.forth};
  }
  @media (max-width: 1550px) {
    padding: 0;
    span {
      font-size: 12px;
      line-height: 13px;
    }
    h2 {
      font-size: 16px;
      line-height: 19px;
    }
  }
  @media (max-width: 991.98px) {
    padding: 0 20px;
  }
  @media (max-width: 420px) {
    padding: 0 10px;
  }
`;
