import React, { useState, useEffect } from "react";
import { Badge, Menu } from "antd";
import {
  MenuIconWrap,
  MenuItem,
  Navigation,
  SidebarContainer,
  SidebarMenu,
  SubMenu,
  SideMenuGroup,
  SidebarTopMenu,
  SidebarDivider,
} from "./TheLayout.styles";
import { ApplicationsSvg, TenantsSvg, AvenewLogo } from "assets/svgs";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { StyledLeftSideMenu } from "./header/TheHeader.styles";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import { slugify, _get, _set } from "pages/utils/fn";
import Applications from "pages/Applications/Applications";
import { AiOutlineDashboard, AiOutlineUsergroupAdd } from "react-icons/ai";
import { GoOrganization } from "react-icons/go";

function Sidebar() {
  const location = useLocation();

  const routeOrg = "/property/:id";
  // eslint-disable-next-line no-unused-vars
  const [modalState, setModalState] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [visibleScreen, setVisible] = useState("");
  const [allOrg, setAllOrg] = useState([]);
  const navigate = useNavigate();

  const { SubMenu } = Menu;

  // Menu option for side bar
  const menu = [
    {
      name: "Dashboard",
      key: "dashboard",
      component: MenuItem,
      icon: AiOutlineDashboard,
      to: "/",
    },
    // {
    //   name: "Tenants",
    //   key: "applications",
    //   component: MenuItem,
    //   icon: TenantsSvg,
    //   to: "/tenants",
    // },
    {
      name: "Users",
      key: "users",
      component: MenuItem,
      icon: AiOutlineUsergroupAdd,
      to: "/users",
    },
    {
      name: "Organizations",
      key: "organizations",
      component: MenuItem,
      icon: ApplicationsSvg,
      to: "/organizations",
    },
    {
      name: "Profile Verification",
      key: "profileverification",
      component: MenuItem,
      icon: ApplicationsSvg,
      children: [
        {
          name: "Pay Stubs",
          key: "paystubs",
          component: MenuItem,
          // icon: ApplicationsSvg,
          to: "/paystubs",
        },
        {
          name: "Employer Details",
          key: "employer",
          component: MenuItem,
          // icon: ApplicationsSvg,
          to: "/employer-list",
        },
        {
          name: "Rental History",
          key: "rentalhistory",
          component: MenuItem,
          // icon: ApplicationsSvg,
          to: "/rental-history",
        },
      ],
    },
  ];

  // Menu option for view all or particular organization
  // const menuTwo = [
  //   {
  //     name: firstOrgName.charAt(0).toUpperCase() + firstOrgName.slice(1),
  //     key: "org",
  //     component: SubMenu,
  //     icon: MdKeyboardArrowDown,
  //     children: [
  //       {
  //         name: `View All`,
  //         // icon: BsPlus,
  //         key: "view-all",
  //         component: SidebarDivider,
  //         // to: "/listings",
  //         toSave: true,
  //         // onClick: onClickFun,
  //       },
  //       {
  //         name: `Add New Org`,
  //         icon: BsPlus,
  //         key: "add-new-org",
  //         component: MenuItem,
  //         // to: "/dashboard",
  //         onClick: onClickFun,
  //         className: "add__org",
  //       },
  //     ],
  //   },
  // ];
  // const menuTwoOne = [
  //   {
  //     name: firstOrgName.charAt(0).toUpperCase() + firstOrgName.slice(1),
  //     key: "org",
  //     component: SubMenu,
  //     icon: MdKeyboardArrowDown,
  //     children: [
  //       {
  //         name: `Add New Org`,
  //         icon: BsPlus,
  //         key: "add-new-org",
  //         component: MenuItem,
  //         // to: "/dashboard",
  //         onClick: onClickFun,
  //         className: "add__org",
  //       },
  //     ],
  //   },
  // ];

  // Split the value or a name w.r.t length of a string
  const slpitFunc = (value) => {
    if (value.length > 15) {
      return value.slice(0, 15) + "...";
    } else {
      return value;
    }
  };

  // Funtion to convert alphabets to upper case
  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [topMenu, setTopMenu] = useState(menu);

  // const [orgList, setOrgList] = useState(
  //   allOrg.length > 1 ? menuTwo : menuTwoOne
  // );

  // useEffect(() => {
  //   topMenu.map((value) => {
  //     if (value?.name === "Listings & Units") {
  //       value.to = routeOrgSide;
  //     } else {
  //     }
  //   });
  // }, [routeOrgSide]);

  const [openKeys, setOpenKeys] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [addNew, setAddNew] = useState(false);

  //
  const findKey = (menu, parent = "dashboard") => {
    for (const item of menu) {
      if (item.children) {
        findKey(item.children, `${parent}.${item.key}`);
      } else {
        if (item.to === location.pathname) {
          let path = parent.split(".");
          setActiveKey(item.key);
          setOpenKeys(path.slice(1));
          // break;
        } else if (
          item.to === "/application" &&
          location.pathname === "/applications"
        ) {
          let path = parent.split(".");
          setActiveKey("applications");
          setOpenKeys(path.slice(1));
          break;
        }
      }
    }
    return [];
  };

  useEffect(() => {
    findKey(topMenu, topMenu[0].key);
  }, [location]);

  // useEffect(() => {
  //   findKey(
  //     allOrg.length > 1 ? menuTwo : menuTwoOne,
  //     (allOrg.length > 1 ? menuTwo : menuTwoOne)[0].key
  //   );
  // }, [location]);

  // To navigate to addNeworganization Modal
  function getMenuLink(key) {
    switch (key) {
      case "add-new-org":
        return { onClick: () => setModalState(true) };
      default:
        return null;
    }
  }

  // Switch icon conditionally
  // const sliderIcon = (key) => {
  //   switch (key) {
  //     case "dashboard":
  //       return ApplicationsSvg;
  //     case "org":
  //       return MdKeyboardArrowDown;
  //     case "add-new-org":
  //       return BsPlus;
  //     case "focus":
  //       return ApplicationsSvg;
  //     default:
  //       return null;
  //   }
  // };

  // useEffect(() => {
  //   const createMenu = ({ children, key, name, to, className, toSave }) => {
  //     let menuLink = getMenuLink(key);
  //     return {
  //       name: name,
  //       key: `${key}`,
  //       component:
  //         key === "org"
  //           ? SubMenu
  //           : key === "add-new-org"
  //             ? MenuItem
  //             : key === "team-devider"
  //               ? SidebarDivider
  //               : MenuItem,
  //       className: key === "add-new-org" ? "add__org" : "",
  //       // component: getComponentForMenu(
  //       //   key === "team-devider"
  //       //     ? "devider"
  //       //     : children && children.length
  //       //     ? "menu"
  //       //     : key === "org"
  //       //     ? "submenu"
  //       //     : "menu"
  //       // ),
  //       icon: sliderIcon(key),
  //       ...(to ? { to } : {}),
  //       ...(menuLink ? { ...menuLink } : {}),
  //       ...(toSave ? { toSave } : {}),
  //       ...(children && children.length
  //         ? { children: children.map((child) => createMenu(child)) }
  //         : {}),
  //     };
  //   };

  //   const mergeTeamMenu = (mainMenu, orgs) => {
  //     if (mainMenu.key === "org") {
  //       const connectMenu = { ...mainMenu };

  //       const mergeTeamData =
  //         orgs && orgs.length
  //           ? orgs.map((data, idx) => {
  //             return {
  //               name: slpitFunc(data?.org_name),
  //               key: `${slugify(data?.org_name || "org_")}`,
  //               // component: MenuItem,
  //               // to: routeOrg.replace(":id", data?.id),
  //               toSave: data?.id,
  //               // divider: true,
  //             };
  //           })
  //           : [];
  //       if (mergeTeamData.length) {
  //         const teamChildren = _get(mainMenu, "children", []);
  //         const teamLength = mergeTeamData.length;
  //         _set(mergeTeamData, `${teamLength}`, {
  //           // name: "devider",
  //           key: "team-devider",
  //         });
  //         teamChildren?.forEach((tc, idx) => {
  //           _set(mergeTeamData, `${teamLength + idx + 1}`, tc);
  //         });

  //         const modifiedConnectedMenu = {
  //           ...connectMenu,
  //           children: [
  //             ...mergeTeamData.map((connectChild) => {
  //               return {
  //                 ...connectChild,
  //                 // children: mergeTeamData,
  //               };
  //             }),
  //           ],
  //         };
  //         return modifiedConnectedMenu;
  //       }
  //     }
  //     return mainMenu;
  //   };

  //   let rebuildMenu = [];

  //   if (menuTwo && allOrg) {
  //     menuTwo &&
  //       (allOrg.length > 1 ? menuTwo : menuTwoOne).map((data, idx) => {
  //         _set(rebuildMenu, idx, createMenu(mergeTeamMenu(data, allOrg)));
  //       });

  //     setOrgList(() => {
  //       let oldMenu = [...rebuildMenu];
  //       return oldMenu;
  //     });
  //   }

  //   return () => { };
  // }, [allOrg]);

  function onOpenChange(keys, children) {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  }

  const renderMenu = (menu, className = "") => {
    return menu.map(
      (
        {
          name,
          key,
          to,
          onClick,
          component: Item,
          icon: Icon,
          children,
          toSave,
          className: className,
          divider,
        },
        idx
      ) => {
        return (
          <Item
            key={key}
            className={className}
            icon={
              Icon && (
                <MenuIconWrap>
                  <Icon />
                </MenuIconWrap>
              )
            }
            title={children && name}
            level={idx + 1}
          >
            {children ? (
              renderMenu(children, `sub-children ${key}`)
            ) : to ? (
              <>
                {/* <Link to={to} onClick={() => localStorage.setItem("orgName", name)}> */}
                {/* {key === "messages" ? (
                  <Badge count={allNotificatios?.notifications?.length}>
                    <Link
                      to={to}
                      onClick={() => {
                        setVisible(name);
                        // firstOrgNameFun(name);
                      }}
                    >
                      {upperCase(name)}
                    </Link>
                  </Badge>
                ) : ( */}
                <Link
                  to={to}
                  onClick={() => {
                    setVisible(name);
                    // firstOrgNameFun(name);
                  }}
                >
                  {upperCase(name)}
                </Link>
                {/* )} */}
              </>
            ) : onClick ? (
              <Link
                to={window.location.pathname}
                onClick={() => setModalState(true)}
              >
                {name}
              </Link>
            ) : toSave ? (
              <>
                {location.pathname.includes("property") ||
                location.pathname.includes("listings") ? (
                  <Link
                    to={toSave === true ? "/listings" : `/property/${toSave}`}
                    // onClick={() => setLocalId(toSave, name)}
                  >
                    {upperCase(name)}
                  </Link>
                ) : (
                  <Link
                    to={window.location.pathname}
                    // onClick={() => setLocalId(toSave, name)}
                  >
                    {upperCase(name)}
                  </Link>
                )}
              </>
            ) : (
              name
            )}
          </Item>
        );
      }
    );
  };

  return (
    <>
      <SidebarContainer className="left-panel">
        <Navigation>
          <SideMenuGroup>
            <StyledLeftSideMenu>
              <Link to="/" className="nav-link avenew__title__sidebar">
                <AvenewLogo />
              </Link>
            </StyledLeftSideMenu>
            <SidebarTopMenu
              triggerSubMenuAction="click"
              mode="horizontal"
              openKeys={openKeys}
              selectedKeys={activeKey}
              onOpenChange={onOpenChange}
            >
              {/* {renderMenu(orgList)} */}
            </SidebarTopMenu>
            <SidebarMenu
              mode="inline"
              // openKeys={openKeys}
              selectedKeys={activeKey}
              onOpenChange={onOpenChange}
            >
              {/* {renderMenu(topMenu)} */}
              <MenuItem key="dashboard" icon={<AiOutlineDashboard />}>
                <Link to="/">Dashboard</Link>
              </MenuItem>
              <MenuItem key="users" icon={<AiOutlineUsergroupAdd />}>
                <Link to="/users">Users</Link>
              </MenuItem>
              <MenuItem key="organizations" icon={<GoOrganization />}>
                <Link to="/organizations">Organization</Link>
              </MenuItem>
              <SubMenu
                key="profile-verification"
                icon={<ApplicationsSvg />}
                title={<span>Profile Verification</span>}
              >
                <MenuItem key="paystubs" className="menu__child">
                  <Link to="/paystubs">Paystubs</Link>
                </MenuItem>
                <MenuItem key="employer" className="menu__child">
                  <Link to="/employer-list">Employer Details</Link>
                </MenuItem>
                <MenuItem key="rentalhistory" className="menu__child">
                  <Link to="/rental-history">Rental History</Link>
                </MenuItem>
              </SubMenu>
            </SidebarMenu>
          </SideMenuGroup>
        </Navigation>
      </SidebarContainer>
    </>
  );
}

export default Sidebar;
