import { Col, Dropdown, Input, Menu, Row, Table, message } from "antd";
import { httpAPIInviteSND } from "api";
import { DiscountTable } from "pages/Applications/Applications";
import { Capitalize, upperCase } from "pages/utils/fn";
import useDebounce from "pages/utils/hooks/useDebounce";
import React, { useCallback, useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import { StatusButton } from "./Paystubs";
import { useDispatch, useSelector } from "react-redux";
import { verifyTenantData } from "store/reducers/user.reducer";

// Switch case for font color
export const statusFontColor = (status) => {
  switch (status) {
    case "Pending":
      return "#4160AC";
    case "Approved":
      return "#58D255";
    case "Denied":
      return "#E04141";
    default:
      return "#ffffff";
  }
};

const EmployerDetail = () => {
  const [searchBarText, setSearchBarText] = useState("");
  const [loading, setLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);
  const [indexVal, setIndexVal] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    size: "small",
    showSizeChanger: true,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Search = Input.Search;
  const { user } = useSelector((state) => state.user);

  const deBounceSearchBarText = useDebounce(searchBarText.trim(), 1000);

  // Calling Api with or without search text
  useEffect(() => {
    if (deBounceSearchBarText?.length >= 3) {
      setLoading(true);
      httpAPIInviteSND(
        `/profile-verification-data`,
        {
          pageSize: 10,
          pageNumber: 1,
          type: "employment",
          query: `${encodeURIComponent(deBounceSearchBarText)}`,
        },
        "POST"
      ).then((res) => {
        setPagination((p) => ({ ...p, total: res?.data?.total }));
        setSearchData(res?.data?.Data);
        setLoading(false);
      });
    } else {
      let newPagination = {
        ...pagination,
      };
      getEmployerDetailList(newPagination, searchBarText);
    }
  }, [deBounceSearchBarText]);

  // Calling API to get table data
  const getEmployerDetailList = useCallback(
    (pagination, search = "") => {
      setLoading(true);
      httpAPIInviteSND(
        `/profile-verification-data`,
        {
          ...pagination,
          type: "employment",
          query: encodeURIComponent(search),
        },
        "POST"
      )
        .then((payload) => {
          if (payload?.data) {
            setSearchData(payload?.data?.Data);
            setPagination((p) => ({ ...p, total: payload?.data?.total }));
          } else {
            setSearchData([]);
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error) {
            message.error(error.message);
          }
        })
        .finally(() => {});
    },
    [pagination]
  );

  // set search text upon search event
  const onSearch = (e) => {
    setSearchBarText(e.target.value);
  };

  // Function to call upon onchange event of table
  const handleTableChange = (newPagination, filters, sorter) => {
    setLoading(true);
    newPagination.pageNumber = newPagination.current;
    getEmployerDetailList(newPagination, searchBarText);
    setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
  };

  // Empty search bar text
  const emitEmpty = () => {
    setSearchBarText("");
  };

  const suffix = <MdCancel onClick={() => emitEmpty()} />;

  // Function to approve Employement details
  const approveEmployement = (item) => {
    setApproveLoading(true);
    let data = {};
    data.document_name = "employer_details";
    data.user_id = item?.id;
    data.status = 1;
    data.admin_id = user?.id;
    dispatch(verifyTenantData(data)).then(({ payload }) => {
      setApproveLoading(false);
      if (payload) {
        message.info(
          `${upperCase(item?.firstName)}'s Employer Detail Approved `
        );
        let newPagination = {
          ...pagination,
        };
        getEmployerDetailList(newPagination, searchBarText);
      }
    });
  };

  // Function to deny Employement details
  const deniedEmployement = (e, item) => {
    setDenyLoading(true);
    let data = {};
    data.document_name = "employer_details";
    data.user_id = item?.id;
    data.status = 2;
    data.employment_reject_reason = e.key;
    data.admin_id = user?.id;
    dispatch(verifyTenantData(data)).then(({ payload }) => {
      setDenyLoading(false);
      if (payload) {
        message.info(`${upperCase(item?.firstName)}'s Employer Detail Denied `);
        let newPagination = {
          ...pagination,
        };
        getEmployerDetailList(newPagination, searchBarText);
      }
    });
  };

  // columns for table
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      render: (_, record) => {
        return (
          <div>
            <td>
              {Capitalize(record.firstName)} {Capitalize(record.lastName)}
            </td>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => {
        return (
          <div
            style={{
              color: statusFontColor(
                record?.status === 0
                  ? "Pending"
                  : record?.status === 1
                  ? "Approved"
                  : "Denied"
              ),
            }}
          >
            {record?.status === 0
              ? "Pending"
              : record?.status === 1
              ? "Approved"
              : "Denied"}
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "",
      // align: "center",
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
          },
        };
      },
      render: (_, record, index) => {
        return (
          <>
            {record?.status === 0 ? (
              <Row gutter={[10, 10]} justify="start">
                <Col>
                  <Dropdown
                    trigger="click"
                    overlay={() => items(record, index)}
                    placement="top"
                  >
                    <StatusButton
                      loading={indexVal === index && denyLoading}
                      bgColor="#2B353F"
                    >
                      Deny
                    </StatusButton>
                  </Dropdown>
                </Col>
                <Col>
                  <StatusButton
                    bgColor="#F67C36"
                    loading={indexVal === index && approveLoading}
                    onClick={() => {
                      approveEmployement(record);
                      setIndexVal(index);
                    }}
                  >
                    Approve
                  </StatusButton>
                </Col>
              </Row>
            ) : record?.status === 1 ? (
              <Row gutter={[10, 10]} justify="start">
                <Col>
                  <Dropdown
                    trigger="click"
                    overlay={() => items(record, index)}
                    placement="top"
                  >
                    <StatusButton
                      bgColor="#2B353F"
                      loading={indexVal === index && denyLoading}
                    >
                      Deny
                    </StatusButton>
                  </Dropdown>
                </Col>
              </Row>
            ) : (
              <Row gutter={[10, 10]} justify="start">
                <Col>
                  <StatusButton
                    bgColor="#F67C36"
                    loading={indexVal === index && approveLoading}
                    onClick={() => {
                      approveEmployement(record);
                      setIndexVal(index);
                    }}
                  >
                    Approve
                  </StatusButton>
                </Col>
              </Row>
            )}
          </>
        );
      },
    },
  ];

  // option to show when deny button is clicked
  const items = (val, idx) => {
    setIndexVal(idx);
    return (
      <Menu onClick={(e) => deniedEmployement(e, val)}>
        <Menu.Item key="suspicious">
          <h3>Suspicious</h3>
        </Menu.Item>
        <Menu.Item key="fraud">
          <h3>Fraud</h3>
        </Menu.Item>
        <Menu.Item key="inclusive">
          <h3>Inclusive</h3>
        </Menu.Item>
        <Menu.Item key="other">
          <h3>Other</h3>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Container paddingSm="40px 40px 0 265px">
      <Row gutter={[20, 20]} justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Heading>
            <Link to="/employer-list">Employer Details</Link>
          </Heading>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={8}
          className="search__filter"
        >
          <Search
            ref={(ele) => searchBarText === ele}
            suffix={suffix}
            placeholder="Search..."
            value={searchBarText}
            onChange={onSearch}
            onPressEnter={onSearch}
          />
        </Col>
      </Row>
      <DiscountTable>
        <Table
          className="app__table"
          columns={columns}
          dataSource={[...searchData]}
          loading={loading}
          onChange={handleTableChange}
          pagination={pagination}
          rowKey={(record, index) => index}
          onRow={(item) => ({
            onClick: () => {
              localStorage.setItem("prospect_userId", item?.id);
              navigate(`/employer-detail/${item?.id}`);
            },
          })}
          scroll={{ x: 1300, y: 550 }}
        />
      </DiscountTable>
    </Container>
  );
};

export default EmployerDetail;
