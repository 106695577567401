import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import PersonalDetails from "./PersonalDetails";
import { useLocation } from "react-router";
import ChangePassword from "./ChangePassword";
import { useDispatch } from "react-redux";
import EditPersonalDetails from "./EditPersonalDetails";
import { useWindowSize } from "pages/utils/hooks/useWindowSize";

function MyAccountSidebar({ isEdit, isEditTab, imgState, onTabChangeFun }) {
  const dispatch = useDispatch();
  // const { openAccountTab, value } = useSelector((state) => state.user);
  const { TabPane } = Tabs;
  const { width } = useWindowSize();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("personal-details");
  const [tabPosition, setTabPosition] = useState("left");

  // To dispatch action upon opening sidebar
  useEffect(() => {
    // dispatch(openMyAccountTab(activeTab));
    // dispatch(onEdit(""));
  }, [activeTab]);

  // To set tab position w.r.t screen width
  useEffect(() => {
    if (width < 992) {
      setTabPosition("horizontal");
    } else {
      setTabPosition("left");
    }
  }, [width, location.pathname]);

  return (
    <StyledTabWrapper>
      <StyledTabs
        defaultActiveKey={"personal-details"}
        activeKey={activeTab}
        onChange={(tabKey) => {
          // setList([]);
          onTabChangeFun();
          setActiveTab(tabKey);
        }}
        tabPosition={tabPosition}
      >
        <TabPane tab="Personal Details" key="personal-details">
          {activeTab === "personal-details" && isEditTab ? (
            <EditPersonalDetails imgState={imgState} isEdit={isEdit} />
          ) : (
            <PersonalDetails isEdit={isEdit} />
          )}
        </TabPane>

        <TabPane tab="Change Password" key="change-password">
          <ChangePassword />
        </TabPane>
      </StyledTabs>
    </StyledTabWrapper>
  );
}

export default MyAccountSidebar;

export const StyledListWrapper = styled.div``;
export const StyledTabWrapper = styled.div`
  margin-left: 45px;

  .ant-divider-vertical {
    margin-top: 0px;
    height: 13.9em;
    margin: 0 263px;
    @media (max-width: 1540px) {
      height: 8.9em;
    }

    @media (max-width: 993px) {
      display: none;
    }
  }
  @media (max-width: 992px) {
    ${"" /* margin-left: 30px; */}
    padding: 15px;
  }
  .ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding: 0 25px;
  }

  .ant-tabs-tab:hover {
    color: ${(props) => props.theme.colors.secondary};
  }

  @media (max-width: 1550px) {
    margin-left: 0;
  }
  @media (max-width: 1200px) {
    margin-left: 0;
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 8px 100px 8px 24px !important;
    font-weight: 400;
    font-size: 18px;

    @media (max-width: 1200px) {
      padding: 8px 10px 8px 24px !important;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.secondary};
  }
  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.secondary};
  }

  @media (max-width: 1550px) {
    .ant-tabs-tab {
      padding: 6px 50px 6px 18px !important;
      font-size: 14px;
    }
  }
  @media (max-width: 992px) {
    .ant-tabs-tab {
      padding: 8px 24px 8px 24px !important;
    }
  }
`;
