import { Col, Modal, Row } from "antd";
import { StyledButton } from "components/createPersonalProfile/RegistrationStyle";
import { WarningWrap } from "pages/users/ConfirmDeactivateModal";
import { Capitalize } from "pages/utils/fn";
import React from "react";
import { IoIosClose } from "react-icons/io";

const DeactivateProperty = ({
  onCancel,
  modalState,
  singleRowData,
  switchLoading,
  onSwitchHandler,
}) => {
  return (
    <Modal
      getContainer={() => document.querySelector("#modal-container")}
      visible={modalState}
      onCancel={() => {
        onCancel(false);
      }}
      width={800}
      footer={null}
      // className="modal_wrapper"
      closeIcon={<IoIosClose className="modal_close_icon" />}
      destroyOnClose={true}
    >
      <>
        <WarningWrap>
          <h1 className="modal__title">Deactivate Property</h1>
          <h4 className="modal__content">
            Are you sure you want to Deactivate{" "}
            <span className="user__name">
              {" "}
              {`${Capitalize(singleRowData?.unit_name)}`} ?
            </span>
          </h4>
          <h5 className="warning__msg">
            By Deactivating this Property, User won't be able to access the
            Units under it.
          </h5>
          <Row justify="end" gutter={[20, 20]} style={{ marginTop: "24px" }}>
            <Col>
              <StyledButton hg="40px" onClick={() => onCancel(false)}>
                Cancel
              </StyledButton>
            </Col>
            <Col>
              <StyledButton
                hg="40px"
                loading={switchLoading}
                onClick={() => onSwitchHandler(singleRowData)}
              >
                Yes
              </StyledButton>
            </Col>
          </Row>
        </WarningWrap>
      </>
    </Modal>
  );
};

export default DeactivateProperty;
