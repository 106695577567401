import React, { useEffect, useState } from "react";
import { DetailWrap, StyledButton } from "./ApplicationDetails";
import { Col, Divider, Row, message } from "antd";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  AddedReference,
  DetailsBox,
  ViewAddedReference,
  ViewAllRental,
} from "./EmployerDetails";
import { LessSvg, PlusSvg } from "assets/svgs";
import {
  Capitalize,
  dateConverter,
  formatPhoneNumber,
  formatSsn,
} from "pages/utils/fn";

const StateProfile = ({
  onChangeTab,
  userDataObj,
  onDeclineSubmit,
  onApprovalSubmit,
}) => {
  const [newSetalFields, setNewSetalFields] = useState([]);
  const [openIndexs, setOpenIndexs] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);
  const [openStateIndexs, setOpenStateIndexs] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);

  useEffect(() => {
    if (userDataObj && userDataObj?.state_profile?.length) {
      setNewSetalFields(userDataObj?.state_profile);
    }
  }, [userDataObj]);

  const openIndexFun = (idx) => {
    if (openIndexs?.includes(idx)) {
      var filteredArray = openIndexs?.filter((e) => {
        return e !== idx;
      });
      setOpenIndexs(filteredArray);
    } else {
      setOpenIndexs((val) => [...val, idx]);
    }
  };

  const openStateIndexFun = (idx) => {
    if (openStateIndexs?.includes(idx)) {
      var filteredArray = openStateIndexs?.filter((e) => {
        return e !== idx;
      });
      setOpenStateIndexs(filteredArray);
    } else {
      setOpenStateIndexs((val) => [...val, idx]);
    }
  };

  const onApproval = () => {
    if (userDataObj?.is_state_profile_verified !== 1) {
      onApprovalSubmit("state_profile", userDataObj?.user_id);
      message.info("State Profile Approved");
      // onChangeTab(0);
    } else {
      message.warn("State Profile Already Approved");
      // onChangeTab(0);
    }
  };

  const onDecline = () => {
    if (userDataObj?.is_state_profile_verified !== 2) {
      onDeclineSubmit("state_profile", userDataObj?.user_id);
      message.info("State Profile Declined");
    } else {
      message.warn("State Profile Already Declined");
    }
  };

  return (
    <>
      <DetailWrap>
        <Row className="ant__scroll">
          <Col span={24}>
            <h1>State Profile</h1>
          </Col>
          {newSetalFields?.length ? (
            <>
              {newSetalFields?.map((item, idx) => (
                <>
                  <ViewAllRental
                    mb={openIndexs?.includes(idx) ? "0" : "25px"}
                    key={idx}
                  >
                    <AddedReference
                      bb={
                        openIndexs?.includes(idx) ? "none" : "1px solid #dddddd"
                      }
                      onClick={() => openIndexFun(idx)}
                    >
                      <Row
                        justify="space-between"
                        style={{ alignItems: "center", padding: "0px" }}
                      >
                        <Col>
                          <Row gutter={[10, 10]}>
                            <Col>
                              {" "}
                              <span>{item?.stateName}</span>{" "}
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          {openIndexs?.includes(idx) ? (
                            <>
                              <LessSvg
                                style={{ marginBottom: "5px" }}
                                className="less__svg"
                              />
                            </>
                          ) : (
                            <>
                              <PlusSvg />
                            </>
                          )}
                        </Col>
                      </Row>
                    </AddedReference>
                  </ViewAllRental>

                  {openIndexs?.includes(idx) ? (
                    <ViewAddedReference
                      style={{
                        overflowY: "scroll",
                        overflowX: "hidden",
                        height: "400px",
                      }}
                    >
                      <Row justify="space-between" gutter={[15, 15]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                          <h2>Co-Applicant details</h2>
                          <Row justify="space-between" gutter={[2, 2]}>
                            <Col span={8}>
                              <DetailsBox>
                                <p>First Name</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.fullName}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Last Name</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.lastName}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>SSN</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>
                                  {formatSsn(
                                    item?.spouseDetails?.spouseSocialSecurity
                                  )}
                                </p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Birth Date</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>
                                  {item?.spouseDetails?.birthdate
                                    ? dateConverter(
                                        item?.spouseDetails?.birthdate
                                      )
                                    : ""}
                                </p>
                              </DetailsBox>
                            </Col>
                            <Col span={8}>
                              <DetailsBox>
                                <p>Height</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.height}</p>
                              </DetailsBox>
                            </Col>
                            <Col span={8}>
                              <DetailsBox>
                                <p>Weight</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.weight}</p>
                              </DetailsBox>
                            </Col>
                            <Col span={8}>
                              <DetailsBox>
                                <p>Gender</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.sex}</p>
                              </DetailsBox>
                            </Col>
                            <Col span={8}>
                              <DetailsBox>
                                <p>Eye Color</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.eyeColor}</p>
                              </DetailsBox>
                            </Col>
                            <Col span={8}>
                              <DetailsBox>
                                <p>Hair Color</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.hairColor}</p>
                              </DetailsBox>
                            </Col>
                            <Col span={8}>
                              <DetailsBox>
                                <p>Are You Us Citizen</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.USCitizen}</p>
                              </DetailsBox>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                          <h2>Co-Applicant Company Details</h2>
                          <Row justify="space-between" gutter={[2, 2]}>
                            <Col span={8}>
                              <DetailsBox>
                                <p>Present Employer</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.presentEmployer}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Address</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.address}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>State</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.state}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>City</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.city}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Zip</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.zipcode}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Work Phone</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>
                                  {formatPhoneNumber(
                                    item?.spouseDetails?.workPhone
                                  )}
                                </p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Position</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.positions}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Monthly Income</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.spouseDetails?.monthlyIncome}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Joining Date</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>
                                  {item?.spouseDetails?.begenJob
                                    ? dateConverter(
                                        item?.spouseDetails?.begenJob
                                      )
                                    : ""}
                                </p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Supervior Name</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>
                                  {item?.spouseDetails?.supervisorNameAndPhone}
                                </p>
                              </DetailsBox>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Divider />
                      <Row justify="space-between" gutter={[15, 15]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                          <h2>Applicant Occupation</h2>
                          {Object.keys(item?.workDetails || {}).length ? (
                            <Row justify="space-between" gutter={[2, 2]}>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Work Phone</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>
                                    {formatPhoneNumber(
                                      item?.workDetails?.workPhone
                                    )}
                                  </p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Position</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.workDetails?.position}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Monthly Income</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.workDetails?.monthlyIncome}</p>
                                </DetailsBox>
                              </Col>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Joining Date</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>
                                    {item?.workDetails?.begenJob
                                      ? dateConverter(
                                          item?.workDetails?.begenJob
                                        )
                                      : ""}
                                  </p>
                                </DetailsBox>
                              </Col>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Supervisor Name</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.workDetails?.supervisorName}</p>
                                </DetailsBox>
                              </Col>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Supervisor Phone</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>
                                    {formatPhoneNumber(
                                      item?.workDetails?.supervisorPhone
                                    )}
                                  </p>
                                </DetailsBox>
                              </Col>
                            </Row>
                          ) : (
                            <h3>Data Not Found</h3>
                          )}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                          <h2>Credit History</h2>
                          {Object.keys(item?.creditHistory || {}).length ? (
                            <Row justify="space-between" gutter={[2, 2]}>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Bank Name</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.creditHistory?.bankName}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>State</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.creditHistory?.state}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>City</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.creditHistory?.city}</p>
                                </DetailsBox>
                              </Col>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Zip Code</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>
                                    <p>{item?.creditHistory?.zipcode}</p>
                                  </p>
                                </DetailsBox>
                              </Col>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Credit Cards</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.creditHistory?.majorCreditCards}</p>
                                </DetailsBox>
                              </Col>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Alternate Income</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.creditHistory?.nonWorkIncome}</p>
                                </DetailsBox>
                              </Col>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Past Credit Issue</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.creditHistory?.creditProblems}</p>
                                </DetailsBox>
                              </Col>
                            </Row>
                          ) : (
                            <h3>Data Not Found</h3>
                          )}
                        </Col>
                      </Row>
                      <Divider />
                      <Row justify="space-between" gutter={[15, 15]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                          <h2>Criminal History</h2>
                          <Row justify="space-between" gutter={[2, 2]}>
                            {item?.rentalOrCriminalHistory?.length ? (
                              <>
                                {item?.rentalOrCriminalHistory?.map(
                                  (val, ind) => (
                                    <Col span={24}>
                                      <DetailsBox>
                                        <li key={ind}>
                                          <p># {val}</p>
                                        </li>
                                      </DetailsBox>
                                    </Col>
                                  )
                                )}
                              </>
                            ) : (
                              <h3>Data Not Found</h3>
                            )}
                          </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                          <h2>Vehicle Details</h2>
                          {Object.keys(item?.vehicleDetails || {}).length ? (
                            <Row justify="space-between" gutter={[2, 2]}>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Make & Color</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.vehicleDetails?.vehicleColor}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Make Year</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.vehicleDetails?.year}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>License</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.vehicleDetails?.license}</p>
                                </DetailsBox>
                              </Col>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>State</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>
                                    <p>{item?.vehicleDetails?.state}</p>
                                  </p>
                                </DetailsBox>
                              </Col>
                            </Row>
                          ) : (
                            <h3>Data Not Found</h3>
                          )}
                        </Col>
                      </Row>
                      <Divider />
                      <Row justify="space-between" gutter={[15, 15]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                          <h2>Additional Details</h2>
                          {Object.keys(item?.whyYouRentedHere || {}).length ? (
                            <Row justify="space-between" gutter={[2, 2]}>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Were You Referred</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.whyYouRentedHere?.referred}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Rental Agency</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.whyYouRentedHere?.nameOfLocator}</p>
                                </DetailsBox>
                              </Col>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Agent Name</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>
                                    {item?.whyYouRentedHere?.nameOfIndividual}
                                  </p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Friend Name</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item?.whyYouRentedHere?.nameOfFriend}</p>
                                </DetailsBox>
                              </Col>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Find us,by Own</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>
                                    <p>{item?.whyYouRentedHere?.findUs}</p>
                                  </p>
                                </DetailsBox>
                              </Col>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Where?</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>
                                    <p>
                                      {item?.whyYouRentedHere?.findUsPlatform}
                                    </p>
                                  </p>
                                </DetailsBox>
                              </Col>
                            </Row>
                          ) : (
                            <h3>Data Not Found</h3>
                          )}
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <h2>Emergency Contacts</h2>
                        {item?.emergencyContact?.length ? (
                          <>
                            {item?.emergencyContact?.map(
                              (
                                {
                                  emergencyName,
                                  address,
                                  state,
                                  city,
                                  zipcode,
                                  workPhone,
                                  homePhone,
                                  relation,
                                },
                                idx
                              ) => (
                                <>
                                  <ViewAllRental
                                    mb={
                                      openStateIndexs?.includes(idx)
                                        ? "0"
                                        : "25px"
                                    }
                                    key={idx}
                                  >
                                    <AddedReference
                                      bb={
                                        openStateIndexs?.includes(idx)
                                          ? "none"
                                          : "1px solid #dddddd"
                                      }
                                      onClick={() => openStateIndexFun(idx)}
                                    >
                                      <Row
                                        justify="space-between"
                                        style={{ alignItems: "center" }}
                                      >
                                        <Col>
                                          <h5>{Capitalize(emergencyName)}</h5>
                                        </Col>
                                        <Col>
                                          {openStateIndexs?.includes(idx) ? (
                                            <>
                                              <LessSvg
                                                style={{ marginBottom: "5px" }}
                                                className="less__svg"
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <PlusSvg />
                                            </>
                                          )}
                                        </Col>
                                      </Row>
                                    </AddedReference>
                                  </ViewAllRental>

                                  {openStateIndexs?.includes(idx) ? (
                                    <ViewAddedReference>
                                      <Row
                                        justify="space-between"
                                        gutter={[15, 15]}
                                      >
                                        <Col
                                          xs={24}
                                          sm={24}
                                          md={24}
                                          lg={24}
                                          xl={12}
                                          xxl={12}
                                        >
                                          <h2>Name & Address</h2>
                                          <Row
                                            justify="space-between"
                                            gutter={[2, 2]}
                                          >
                                            <Col span={8}>
                                              <DetailsBox>
                                                <p>City</p>
                                              </DetailsBox>
                                            </Col>
                                            <Col span={16}>
                                              <DetailsBox color="#000000">
                                                <p>{city}</p>
                                              </DetailsBox>
                                            </Col>

                                            <Col span={8}>
                                              <DetailsBox>
                                                <p>State</p>
                                              </DetailsBox>
                                            </Col>
                                            <Col span={16}>
                                              <DetailsBox color="#000000">
                                                <p>{state}</p>
                                              </DetailsBox>
                                            </Col>

                                            <Col span={8}>
                                              <DetailsBox>
                                                <p>Zipcode</p>
                                              </DetailsBox>
                                            </Col>
                                            <Col span={16}>
                                              <DetailsBox color="#000000">
                                                <p>
                                                  {zipcode ? zipcode : `${" "}`}
                                                </p>
                                              </DetailsBox>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col
                                          xs={24}
                                          sm={24}
                                          md={24}
                                          lg={24}
                                          xl={12}
                                          xxl={12}
                                        >
                                          <h2>Contact Details</h2>
                                          <Row
                                            justify="space-between"
                                            gutter={[2, 2]}
                                          >
                                            <Col span={8}>
                                              <DetailsBox>
                                                <p>Work Phone</p>
                                              </DetailsBox>
                                            </Col>
                                            <Col span={16}>
                                              <DetailsBox color="#000000">
                                                <p>{workPhone}</p>
                                              </DetailsBox>
                                            </Col>

                                            <Col span={8}>
                                              <DetailsBox>
                                                <p>Home Phone</p>
                                              </DetailsBox>
                                            </Col>
                                            <Col span={16}>
                                              <DetailsBox color="#000000">
                                                <p>{homePhone}</p>
                                              </DetailsBox>
                                            </Col>

                                            <Col span={8}>
                                              <DetailsBox>
                                                <p>Relation</p>
                                              </DetailsBox>
                                            </Col>
                                            <Col span={16}>
                                              <DetailsBox color="#000000">
                                                <p>{relation}</p>
                                              </DetailsBox>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </ViewAddedReference>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            )}
                          </>
                        ) : (
                          <h3>Data Not Found</h3>
                        )}
                      </Row>
                    </ViewAddedReference>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              <h2>No Data Found</h2>
            </>
          )}
          <Row
            gutter={[20, 20]}
            className="button__group"
            style={{ alignItems: "center" }}
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IoIosArrowBack
                className="nextback__icon"
                onClick={() => onChangeTab(4)}
              />
              <h3 onClick={() => onChangeTab(4)}>Back</h3>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <StyledButton
                onClick={() => {
                  if (newSetalFields?.length) {
                    onApproval();
                  } else {
                    message.warn("No Data Found to Approve");
                  }
                }}
                bgColor={"#F67C36"}
                disabled={userDataObj?.is_state_profile_verified === 1}
              >
                Approve
              </StyledButton>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <StyledButton
                onClick={() => {
                  if (newSetalFields?.length) {
                    onDecline();
                  } else {
                    message.warn("No Data Found to Decline");
                  }
                }}
                disabled={userDataObj?.is_state_profile_verified !== 1}
                bgColor={"#2B353F"}
              >
                Decline
              </StyledButton>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {/* <h3
                style={{ paddingRight: "15px" }}
                onClick={() => onChangeTab(0)}
              >
                Next
              </h3>
              <IoIosArrowForward
                className="nextback__icon"
                onClick={() => onChangeTab(0)}
              /> */}
            </Col>
          </Row>
        </Row>
      </DetailWrap>
    </>
  );
};

export default StateProfile;
