import { Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../createPersonalProfile/RegistrationStyle";
import { message } from "antd";
import { resetPassword } from "store/reducers/user.reducer";

function ChangePassword() {
  var formRef = React.createRef();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.user);

  const [loadings, setLoadings] = useState(false);
  const [passWordValidation, setPassWordValidation] = useState();

  // Submit onClick function
  const onFinish = async (value) => {
    setPassWordValidation("");
    value.AccessToken = localStorage.getItem("accessToken");
    value.id = user.id;
    setLoadings(true);
    await dispatch(resetPassword(value)).then(({ payload }) => {
      setLoadings(false);
      setPassWordValidation(payload?.Error || payload?.error);
      if (payload?.Error || payload?.error) {
      } else {
        message.success("Password change successfully ");
        form.resetFields();
      }
    });
  };

  // Message to validate form
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  // Change password validation
  useEffect(() => {
    if (
      (passWordValidation?.length &&
        passWordValidation?.includes("Incorrect")) ||
      passWordValidation?.includes(
        "Member must satisfy regular expression pattern"
      )
    ) {
      formRef.current.setFields([
        {
          name: "PreviousPassword",
          errors: ["Old Password is incorrect"],
        },
      ]);
    } else if (
      passWordValidation?.length &&
      passWordValidation?.includes("limit exceeded")
    ) {
      message.warning("Attempt limit exceeded");
    }
  }, [passWordValidation, loadings]);
  return (
    <ChangePasswordWrap>
      <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
        <StyledForms>
          <Form
            layout="vertical"
            onFinish={onFinish}
            requiredMark={"optional"}
            validateMessages={validateMessages}
            ref={formRef}
            form={form}
          >
            <StyledFormItem borderColor="none" wh="100" smwh="100">
              <Form.Item
                name="PreviousPassword"
                label="Old Password"
                className="password-w-icon"
                rules={[
                  {
                    required: true,
                  },
                  {
                    min: 8,
                    message: "password is not correct.",
                  },
                ]}
              >
                <Input.Password type="password" placeholder="Password" />
              </Form.Item>
            </StyledFormItem>
            <StyledFormItem borderColor="none" wh="100" smwh="100">
              <Form.Item
                name="ProposedPassword"
                label="New Password"
                className="password-w-icon"
                rules={[
                  {
                    required: true,
                  },
                  {
                    min: 8,
                    pattern: new RegExp(
                      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                    ),
                    message:
                      "Use 8 and more characters with a mix of letters, numbers & symbols",
                  },
                ]}
              >
                <Input.Password type="password" placeholder="New Password" />
              </Form.Item>
            </StyledFormItem>
            <StyledFormItem borderColor="none" wh="100" smwh="100">
              <Form.Item
                name="confirm"
                label="Confirm New Password"
                dependencies={["ProposedPassword"]}
                rules={[
                  { required: true, message: "Please confirm your password." },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        getFieldValue("ProposedPassword") === value
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match."
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Confirm New Password"
                />
              </Form.Item>
            </StyledFormItem>
            <Form.Item>
              <Row justify="end">
                <Col>
                  <StyledButton htmlType="submit" loading={loadings}>
                    SAVE
                  </StyledButton>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </StyledForms>
      </StyledForm>
    </ChangePasswordWrap>
  );
}

export default ChangePassword;

export const ChangePasswordWrap = styled.div`
  ${"" /* padding: 0 30px 0 60px; */}

  @media (max-width: 1440px) {
    padding: 0;
  }

  @media (max-width: 1550px) {
    padding: 0;

    .ant-form-item {
      input {
        font-size: 16px !important;
        line-height: 19px !important;
      }
    }
    .ant-form-item input {
      height: 40px !important;
    }
  }
  @media (max-width: 991.98px) {
    padding: 0 20px;
  }
  @media (max-width: 420px) {
    padding: 0 10px;
  }
`;
