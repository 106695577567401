import { Checkbox, Col, Input, Row, Switch, Table, message } from "antd";
import { httpAPIInviteSND } from "api";
import { DiscountTable } from "pages/Applications/Applications";
import { _merge } from "pages/utils/fn";
import React, { useCallback, useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import _ from "lodash";
import useDebounce from "pages/utils/hooks/useDebounce";
import { useDispatch } from "react-redux";
import { activeDeactiveOrg } from "store/reducers/user.reducer";
import DeactivateOrgModal from "components/organization/DeactivateOrgModal";
import { useWindowSize } from "pages/utils/hooks/useWindowSize";

function Organization() {
  const [loading, setLoading] = useState(false);
  const [searchBarText, setSearchBarText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [singleRowData, setSingleRowData] = useState({});
  const [switchLoading, setSwitchLoading] = useState(false);
  const [indexVal, setIndexVal] = useState("");
  const [modalState, setModalState] = useState(false);
  const [showDeactive, setShowDeactive] = useState(" ");
  const Search = Input.Search;
  const [bottomMargin, setBottomMargin] = useState("15px");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    size: "small",
    showSizeChanger: true,
  });
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (width > 991) {
      setBottomMargin("16px");
    } else {
      setBottomMargin("8px");
    }
  }, [width]);

  const emitEmpty = () => {
    setSearchBarText("");
  };
  const deBounceSearchBarText = useDebounce(searchBarText.trim(), 500);

  const suffix = <MdCancel onClick={() => emitEmpty()} />;

  const columns = [
    {
      title: "Organization",
      dataIndex: "org_name",
    },
    {
      title: "#No Of Property",
      dataIndex: "propertyCount",
    },
    {
      title: "Actions",
      dataIndex: "",
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
          },
        };
      },
      render: (_, record, index) => {
        return (
          <>
            <Switch
              loading={index === indexVal && switchLoading}
              checkedChildren="Active"
              unCheckedChildren="Deactive"
              defaultChecked={record?.isActive === 1 ? true : false}
              checked={record?.isActive === 1 ? true : false}
              onChange={(e) => {
                setIndexVal(index);
                if (record?.isActive === 1) {
                  setModalState(true);
                  setSingleRowData(record);
                } else {
                  onSwitchHandler(record);
                }
              }}
            />
          </>
        );
      },
    },
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    setLoading(true);
    newPagination.pageNumber = newPagination.current;
    getOrg(newPagination, searchBarText, showDeactive);
    setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
  };

  const onSwitchHandler = (record) => {
    setSwitchLoading(true);
    let data = {};
    if (record?.isActive === 1) {
      const objIndex = searchData?.findIndex((obj) => {
        return obj.id === record.id;
      });
      let objValue = searchData[objIndex];
      objValue.isActive = 0;
      searchData[objIndex] = objValue;
      data.id = record?.id;
      data.status = "0";
      dispatch(activeDeactiveOrg(data)).then(({ payload }) => {
        setSwitchLoading(false);
        setModalState(false);
        let newPagination = { ...pagination };
        getOrg(newPagination, "", showDeactive);
      });
    } else {
      const objIndex = searchData?.findIndex((obj) => {
        return obj.id === record.id;
      });
      let objValue = searchData[objIndex];
      objValue.isActive = 1;
      searchData[objIndex] = objValue;
      data.id = record?.id;
      data.status = "1";
      dispatch(activeDeactiveOrg(data)).then(({ payload }) => {
        setSwitchLoading(false);
        let newPagination = { ...pagination };
        getOrg(newPagination, "", showDeactive);
      });
    }
  };

  const onSearch = (e) => {
    setSearchBarText(e.target.value);
  };

  useEffect(() => {
    if (deBounceSearchBarText?.length >= 3) {
      setLoading(true);
      httpAPIInviteSND(
        `/organizations/all?pageSize=${10}&pageNumber=${1}&query=${encodeURIComponent(
          deBounceSearchBarText
        )}&isActive=${showDeactive}`,
        "",
        "GET"
      ).then((res) => {
        setPagination((p) => ({ ...p, total: res?.data?.total }));
        let value = res?.data?.Data;
        const launchOptimistic = value?.map((elem, idx) => ({
          ...elem,
          key: idx,
        }));
        setSearchData(launchOptimistic);
        setLoading(false);
      });
    } else {
      let newPagination = { ...pagination };
      getOrg(newPagination, "", showDeactive);
    }
  }, [deBounceSearchBarText]);

  const getOrg = useCallback(
    (pagination, search = "", avtivate) => {
      setLoading(true);
      httpAPIInviteSND(
        `/organizations/all?pageSize=${pagination.pageSize}&pageNumber=${
          pagination.pageNumber
        }&query=${encodeURIComponent(search)}&isActive=${
          avtivate ? avtivate : ""
        }`,
        "",
        "GET"
      )
        .then((payload) => {
          if (payload?.data) {
            setPagination((p) => ({ ...p, total: payload?.data?.total }));
            let value = payload?.data?.Data;
            const launchOptimistic = value?.map((elem, idx) => ({
              ...elem,
              key: idx,
            }));
            setSearchData(launchOptimistic);
            setLoading(false);
          } else {
            setSearchData([]);
          }
        })
        .catch((error) => {
          setLoading(false);

          if (error) {
            message.error(error.message);
          }
        });
    },

    [pagination]
  );

  const onChange = (e) => {
    if (e.target.checked) {
      setShowDeactive("0");
      let activeProp = "0";
      let newPagination = {
        ...pagination,
        pageNumber: "1",
        pageSize: "10",
        current: "1",
      };
      getOrg(newPagination, searchBarText, activeProp);
    } else {
      setShowDeactive("");
      getOrg(pagination, searchBarText, " ");
    }
  };

  return (
    <Container paddingSm="40px 40px 0 265px">
      <Row justify="space-between" gutter={[10, 10]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Heading>
            <Link to="/organizations">Organizations</Link>
          </Heading>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={8}
          className="search__filter"
        >
          <Search
            ref={(ele) => searchBarText === ele}
            suffix={suffix}
            placeholder="Search..."
            value={searchBarText}
            onChange={onSearch}
            onPressEnter={onSearch}
          />
        </Col>
        <Col
          span={24}
          style={{ marginBottom: bottomMargin, paddingTop: "10px" }}
        >
          <Checkbox defaultChecked={false} onChange={(e) => onChange(e)}>
            Show only Deactivated Organization
          </Checkbox>
        </Col>
      </Row>

      <DiscountTable>
        <Table
          rowClassName={(record) => record?.isActive === 0 && "disabled-row"}
          columns={columns}
          dataSource={[...searchData]}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 1300, y: 550 }}
          onRow={(item) => ({
            onClick: (event) => {
              // event.isDefaultPrevented();
              if (item?.propertyCount === 0) {
              } else {
                localStorage.setItem("org_id", item?.id);
                navigate(`/apartments/${item.id}`);
              }
            },
          })}
        />
      </DiscountTable>
      <DeactivateOrgModal
        onSwitchHandler={onSwitchHandler}
        singleRowData={singleRowData}
        switchLoading={switchLoading}
        onCancel={() => setModalState(false)}
        modalState={modalState}
      />
    </Container>
  );
}

export default Organization;
