/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-unused-vars */
import React, { createRef, useEffect, useState } from "react";
import { LoginMain, LoginWrap, CreateAccount, StyledBg } from "./style";
import { Row, Col, Form, Input, message } from "antd";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
  StyledButton,
} from "components/createPersonalProfile/RegistrationStyle";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "pages/utils/hooks/useWindowSize";
import { forgotPasswordOtp, login } from "store/reducers/user.reducer";
import { useDispatch } from "react-redux";
import { AvenewLogo } from "assets/svgs";

function Login() {
  var formRef = createRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [invalidUserOrPass, setInvalidUserOrPass] = useState(false);
  const [isForgot, setIsForgot] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [emailValidation, setEmailValidation] = useState("");
  const [modalState, setModalState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingsOtp, setloadingsOtp] = useState(false);
  const [formVal, setFormVal] = useState({});
  const [signUpUserData, setSignUpUserData] = useState({});
  const [asetNewOtp, setSetNewOtp] = useState("");
  const [invalidCode, setInvalidCode] = useState({});
  const [enterOtp, setEnterOtp] = useState(false);

  // To change password after forget password
  // useEffect(() => {
  //   if (asetNewOtp) {
  //     setloadingsOtp(true);
  //     dispatch(forgotPasswordWithOtp(signUpUserData)).then(({ payload }) => {
  //       setInvalidCode(payload);
  //       setloadingsOtp(false);
  //       if (payload?.Error) {
  //       } else {
  //         message.success("Password Changed Successfully");
  //         setModalState(false);
  //         setIsForgot(false);
  //       }
  //     });
  //   }
  // }, [asetNewOtp]);

  const newPasswordChange = (value) => {
    let forgotPass = {};
    const obj = Object.values(
      value.otp0 +
        value.otp1 +
        value.otp2 +
        value.otp3 +
        value.otp4 +
        value.otp5
    );
    const arr = [];
    obj.map((val) => arr.push(val));
    const otpValueInput = arr.join("");
    const otpVal = otpValueInput;

    forgotPass.otp = otpVal;
    forgotPass.email = signUpUserData.email;
    forgotPass.password = value.password;

    if (otpVal?.length) {
      setloadingsOtp(true);
      //   dispatch(forgotPasswordWithOtp(forgotPass)).then(({ payload }) => {
      //     setInvalidCode(payload);
      //     setloadingsOtp(false);
      //     if (payload?.Error) {
      //     } else {
      //       message.success("Password Changed Successfully");
      //       setIsForgot(false);
      //       setEnterOtp(false);
      //     }
      //   });
    }
  };

  // Error or Success message upon otp submit
  useEffect(() => {
    if (invalidCode?.Error) {
      setErrorMessage("Invalid code");
    } else if (invalidCode?.id) {
      setModalState(false);
      message.success("Password Changed Successfully");
    } else {
      setModalState(false);
      setIsForgot(false);
    }
  }, [invalidCode]);

  // Onfinish function for resend OTP
  const onFinishResendOtp = async () => {
    // await dispatch(forgotPasswordOtp({ email: formVal.email })).then(
    //   ({ payload }) => {
    //     if (payload?.Error) {
    //     } else {
    //       message.success("OTP is resent to your Email");
    //     }
    //   }
    // );
  };

  // Onfinish function for send OTP
  const onFinish = async (value) => {
    value.email = value.email.toLowerCase();
    setEmailValidation("");
    setloadings(true);
    await dispatch(login(value)).then((res) => {
      if (res?.payload?.AuthenticationResult?.IdToken) {
        // return navigate("/listings")
        return navigate("/");
      }
      setloadings(false);
      setEmailValidation(res.payload.error || res.payload.Error);
      setInvalidUserOrPass(res.payload.Error?.includes("password"));
    });
  };

  // Function for forget password
  const getOtp = async (value) => {
    setEmailValidation("");
    value.email = value.email.toLowerCase();
    setloadings(true);
    setFormVal(value);
    await dispatch(forgotPasswordOtp(value)).then(({ payload }) => {
      setEmailValidation(payload.error || payload.Error);

      if (payload?.error) {
        setloadings(false);
      } else {
        setloadings(false);
        setEnterOtp(true);
        setIsForgot(false);
        //     setModalState(true);
      }
    });

    setSignUpUserData({
      email: value.email,
      otp: "",
      password: "",
    });
  };

  // To verify Email ID
  useEffect(() => {
    if (
      emailValidation?.length &&
      emailValidation?.includes("Email does not exists")
    ) {
      formRef.current.setFields([
        {
          name: "email",
          errors: ["Email does not exists"],
        },
      ]);
    } else if (
      emailValidation?.length &&
      emailValidation?.includes("Incorrect")
    ) {
      formRef.current.setFields([
        {
          name: "password",
          errors: ["Incorrect Password"],
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValidation, loadings]);

  // Message to validate form
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  const { width } = useWindowSize();
  const location = useLocation();
  const [zoom, setZoom] = useState("100%");

  // Set zoom w.r.t screen width
  useEffect(() => {
    if (width < 1550 && width > 991) {
      setZoom("80%");
    } else {
      setZoom("100%");
    }
  }, [width, location.pathname]);

  const cancel = () => {
    setIsForgot(false);
    setEnterOtp(false);
  };

  return (
    <LoginMain>
      <LoginWrap>
        <Row justify="center">
          <Col
            xl={{ span: 12 }}
            md={{ span: 20 }}
            sm={{ span: 20 }}
            xs={{ span: 22 }}
          >
            <AvenewLogo className="avenew__logo" />
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xl={{ span: 12 }}
            md={{ span: 20 }}
            sm={{ span: 20 }}
            xs={{ span: 22 }}
          >
            <h1 className="personal__profile">
              {isForgot ? "Forgot your password?" : "Sign in"}
            </h1>
          </Col>
        </Row>
        {isForgot ? (
          <StyledForm wh="50" smwh="93" xswh="80" xxswh="91">
            <StyledForms>
              <Form
                layout="vertical"
                onFinish={getOtp}
                requiredMark={"optional"}
                validateMessages={validateMessages}
                ref={formRef}
              >
                <StyledFormItem wh="100" smwh="100">
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </StyledFormItem>
                <Form.Item>
                  <StyledButton htmlType="submit" loading={loadings}>
                    Get OTP
                  </StyledButton>
                </Form.Item>
              </Form>
              <CreateAccount>
                <p>Already have an account? </p>
                <h3 onClick={cancel}>Login Now</h3>
              </CreateAccount>
            </StyledForms>
          </StyledForm>
        ) : (
          <StyledForm wh="50" smwh="93" xswh="80" xxswh="91">
            <StyledForms>
              <Form
                layout="vertical"
                onFinish={onFinish}
                requiredMark={"optional"}
                validateMessages={validateMessages}
                ref={formRef}
              >
                <StyledFormItem wh="100" smwh="100">
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </StyledFormItem>
                <StyledFormItem wh="100" smwh="100" borderColor="none">
                  <Form.Item
                    name="password"
                    label="Password"
                    className="password-w-icon pass__filed"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        min: 8,
                        message: "password should contain minimum 8 characters",
                      },
                    ]}
                  >
                    <Input.Password
                      // iconRender={(visible) =>
                      //   visible ? <AiFillEye /> : <AiFillEyeInvisible />
                      // }
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                </StyledFormItem>
                {/* <Row justify="end">
                  <Col>
                    <p
                      className="forgot__password"
                      onClick={() => setIsForgot(true)}
                    >
                      Forgot Password?
                    </p>
                  </Col>
                </Row> */}
                {invalidUserOrPass ? (
                  <p style={{ color: "#ff4d4f" }}>
                    * Incorrect Email or Password.
                  </p>
                ) : (
                  ""
                )}
                <Form.Item>
                  <StyledButton htmlType="submit" loading={loadings}>
                    Sign In
                  </StyledButton>
                </Form.Item>
              </Form>
            </StyledForms>
          </StyledForm>
        )}
      </LoginWrap>
      <StyledBg></StyledBg>
    </LoginMain>
  );
}

export default Login;
