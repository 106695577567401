import {
  Col,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  List,
  Menu,
  Row,
  Skeleton,
  message,
} from "antd";
import { UploadFileSvg } from "assets/svgs";
import { ImagePreview } from "components/imagePreview";
import { StatusButton } from "pages/profile-verification/Paystubs";
import { upperCase, utcToLocal } from "pages/utils/fn";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addLog,
  addNote,
  getHistory,
  getPaystubOne,
  getPaystubTwo,
  verifyTenantData,
} from "store/reducers/user.reducer";
import styled from "styled-components";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import { ContainerWrap } from "ui/Layout/ContainerWrap";
import {
  ButtonWrap,
  StyledUploadPreviewWrapper,
} from "./RentalHistoryDetailPage";

const PayStubsDetailsPage = () => {
  const [payStubObj, setPayStubObj] = useState();
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [callBtnLoading, setCallBtnLoading] = useState(false);
  const [ticketBtnLoading, setTicketBtnLoading] = useState(false);
  const [approveBtnLoading, setApproveBtnLoading] = useState(false);
  const [denyBtnLoading, setDenyBtnLoading] = useState(false);
  const [isPaystubVerified, setIsPaystubVerified] = useState();
  const [historyObj, setHistoryObj] = useState({});
  const [historyArr, setHistoryArr] = useState([]);
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const upload = React.useRef(null);
  const drop = React.useRef(null);
  const dispatch = useDispatch();

  const userId = localStorage.getItem("prospect_userId");
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    setLoading(true);
    if (localStorage.getItem("payStub_type") === "pay_stub1") {
      dispatch(getPaystubOne()).then(({ payload }) => {
        setPayStubObj(payload);
        setIsPaystubVerified(payload?.status);
        setLoading(false);
      });
    } else {
      dispatch(getPaystubTwo()).then(({ payload }) => {
        setPayStubObj(payload);
        setIsPaystubVerified(payload?.status);
        setLoading(false);
      });
    }
  }, [localStorage.getItem("payStub_type")]);

  useEffect(() => {
    if (historyObj && Object.keys(historyObj).length) {
      let arr = Object.values(historyObj);
      setHistoryArr(arr);
    }
  }, [historyObj]);

  const approvePaystub = () => {
    setApproveBtnLoading(true);
    let data = {};
    if (payStubObj?.type === "pay_stub1") {
      data.user_id = userId;
      data.admin_id = user?.id;
      data.document_name = "pay_stub1";
      data.status = 1;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setApproveBtnLoading(false);
        if (payload) {
          getHistoryList();
          setIsPaystubVerified(payload?.is_paystub1_verified);
          message.info(`${upperCase(payStubObj?.name)}'s PayStub1 Approved `);
        }
      });
    } else {
      data.user_id = userId;
      data.admin_id = user?.id;
      data.document_name = "pay_stub2";
      data.status = 1;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setApproveBtnLoading(false);
        if (payload) {
          getHistoryList();
          setIsPaystubVerified(payload?.is_paystub2_verified);
          message.info(`${upperCase(payStubObj?.name)}'s Paystub2 Approved `);
        }
      });
    }
  };

  const denyPaystub = (e) => {
    setDenyBtnLoading(true);
    let data = {};
    if (payStubObj?.type === "pay_stub1") {
      data.user_id = userId;
      data.admin_id = user?.id;
      data.document_name = "pay_stub1";
      data.paystub1_reject_reason = e.key;
      data.status = 2;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setDenyBtnLoading(false);
        if (payload) {
          getHistoryList();
          setIsPaystubVerified(payload?.is_paystub1_verified);
          message.info(`${upperCase(payStubObj?.name)}'s Paystub1 Denied `);
        }
      });
    } else {
      data.user_id = userId;
      data.admin_id = user?.id;
      data.document_name = "pay_stub2";
      data.paystub2_reject_reason = e.key;
      data.status = 2;
      dispatch(verifyTenantData(data)).then(({ payload }) => {
        setDenyBtnLoading(false);
        if (payload) {
          getHistoryList();
          setIsPaystubVerified(payload?.is_paystub2_verified);
          message.info(`${upperCase(payStubObj?.name)}'s Paystub2 denied `);
        }
      });
    }
  };

  const addLogAction = (logType) => {
    let data = {};
    if (logType === "call") {
      setCallBtnLoading(true);
      data.userid = userId;
      data.type = "called";
      data.admin_id = user?.id;
      data.contant_info = payStubObj?.phone_no;
      if (payStubObj?.type === "pay_stub1") {
        data.document_type = "pay_stub1";
      } else {
        data.document_type = "pay_stub2";
      }
      dispatch(addLog(data)).then(({ payload }) => {
        setCallBtnLoading(false);
        getHistoryList();
      });
    } else {
      setTicketBtnLoading(true);
      data.userid = userId;
      data.type = "opened_ticket";
      data.admin_id = user?.id;
      data.contant_info = payStubObj?.email;
      if (payStubObj?.type === "pay_stub1") {
        data.document_type = "pay_stub1";
      } else {
        data.document_type = "pay_stub2";
      }
      dispatch(addLog(data)).then(({ payload }) => {
        setTicketBtnLoading(false);
        getHistoryList();
      });
    }
  };

  const items = (
    <Menu onClick={(e) => denyPaystub(e)}>
      <Menu.Item key="suspicious">
        <span>Suspicious</span>
      </Menu.Item>
      <Menu.Item key="fraud">
        <span>Fraud</span>
      </Menu.Item>
      <Menu.Item key="inclusive">
        <span>Inclusive</span>
      </Menu.Item>
      <Menu.Item key="other">
        <span>Other</span>
      </Menu.Item>
    </Menu>
  );

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
  }, []);

  const fileChangeHandler = async (event) => {
    setImage([]);
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setImage((s) => [
        ...s,
        {
          file: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
          ext: img.type,
        },
      ]);
    };

    Array(...event.target.files) &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          let fileExtension = [
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
            "jfif",
            "tiff",
          ];
          let filename = file.name;
          const fileExt = filename
            .substring(filename.lastIndexOf(".") + 1)
            .toLowerCase();
          if (fileExtension.includes(fileExt)) {
            return await newFun(file);
          } else {
            return message.warning("Please upload Image file");
          }
        })
      ));
  };

  const deleteImgInput = () => {
    const inputImg = document.getElementById("myInput");
    inputImg.value = null;
  };

  const onFinish = (data) => {
    setLoading(true);
    data.userid = userId;
    data.admin_id = user?.id;
    data.note = data.note;
    if (image?.length) {
      data.attachment = image[0];
    } else {
      data.attachment = {};
    }
    data.document_type =
      payStubObj?.type === "pay_stub1" ? "pay_stub1" : "pay_stub2";
    dispatch(addNote(data)).then(({ payload }) => {
      setLoading(false);
      if (payload) {
        getHistoryList();
        message.success("Form submitted Successfully");
      }
    });
    setImage([]);
    form.resetFields();
  };

  const attachmentLink = (item) => {
    let str = item?.note;
    if (str === null || str === "") return false;
    else str = str.toString();
    let text = str.replace(/(<([^>]+)>)/gi, "");
    return (
      <>
        <Row>
          <Col span={24}>{text}</Col>
          <Col span={24}>
            {item?.note_attachment_URL ? (
              <a href={item?.note_attachment_URL} target="blank">
                Attachment Link
              </a>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </>
    );
  };

  const getHistoryList = () => {
    let data = {};
    if (payStubObj?.type === "pay_stub1") {
      data.document_type = "pay_stub1";
    } else {
      data.document_type = "pay_stub2";
    }
    data.userid = userId;
    dispatch(getHistory(data)).then(({ payload }) => {
      setHistoryObj(payload);
    });
  };

  return (
    <Container paddingSm="40px 40px 0 265px">
      <Row gutter={[20, 20]} justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Heading>
            <Link to="">Paystubs Detail</Link>
          </Heading>
        </Col>
      </Row>
      <ContainerWrap
        minH="100%"
        brRadius="5px"
        bgcolor="#ffffff"
        paddings="10px"
      >
        <DetailPageWrap>
          <Row justify="space-between" className="header__status">
            <Col>
              <h2>{upperCase(payStubObj?.name)}</h2>
            </Col>
            <Col>
              <h4>
                {isPaystubVerified === 0
                  ? "Pending"
                  : isPaystubVerified === 1
                  ? "Approved"
                  : "Denied"}
              </h4>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Row
                // gutter={[10, 10]}
                style={
                  {
                    // backgroundColor: "#d9d9d9"
                    // padding: "10px",
                  }
                }
              >
                <Col>
                  {!payStubObj?.data?.length && (
                    <Skeleton.Image
                      width="100%"
                      height="100%"
                      active
                      loading={loading}
                    ></Skeleton.Image>
                  )}
                  {payStubObj?.data?.length && (
                    <Image
                      width="100%"
                      height="100%"
                      src={payStubObj?.data ? payStubObj?.data[0]?.path : ""}
                    />
                  )}
                </Col>
              </Row>
              <Row style={{ paddingTop: "20px" }}>
                <Col span={24}>
                  <h2>
                    {upperCase(
                      payStubObj?.type === "pay_stub1"
                        ? "Pay stub 1"
                        : "Pay stub 2"
                    )}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Form form={form} onFinish={onFinish}>
                  <Form.Item
                    name="note"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Your Message is required",
                      },
                    ]}
                  >
                    <Col span={24}>
                      <TextArea
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        maxLength={500}
                        placeholder="Type Your Message here"
                      />
                    </Col>
                  </Form.Item>
                  <Form.Item name="attachment">
                    <Row>
                      <Col>
                        <StyledUploadPreviewWrapper isPhoto={image.length}>
                          <ImagePreview
                            key={""}
                            src={image.length ? image[0].path : ""}
                            isCoverDisProp={false}
                            height={95}
                            width={95}
                            onDelete={() => {
                              const st = [];
                              setImage(st);
                              deleteImgInput();
                              upload.current.value = null;
                            }}
                          />
                        </StyledUploadPreviewWrapper>
                      </Col>
                    </Row>
                    <Col span={24}>
                      <UploadBox
                        ref={drop}
                        onClick={(e) => upload.current.click()}
                      >
                        <UploadInput>
                          <p className="ant-upload-drag-icon">
                            <UploadFileSvg className="upload__icon" />
                          </p>
                          <p className="upload__icon">Upload</p>
                        </UploadInput>
                      </UploadBox>
                      <input
                        accept="image/*"
                        id="myInput"
                        multiple={"multiple"}
                        type="file"
                        ref={upload}
                        style={{ display: "none" }}
                        onChange={fileChangeHandler}
                      />
                    </Col>
                  </Form.Item>
                  <Form.Item>
                    <Row className="save__btn" justify="end">
                      <Col>
                        <StatusButton bgColor="#F67C36" htmlType="submit">
                          Save
                        </StatusButton>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <List
                header={<h2>History</h2>}
                footer={null}
                loading={loading}
                bordered
                dataSource={
                  historyArr?.length ? historyArr : payStubObj?.history
                }
                renderItem={(item) => (
                  <>
                    <List.Item extra={utcToLocal(item?.logtime)}>
                      <Skeleton
                        title
                        paragraph={{ rows: 1 }}
                        loading={loading}
                        active
                      >
                        <List.Item.Meta
                          description={attachmentLink(item)}
                        ></List.Item.Meta>
                      </Skeleton>
                    </List.Item>
                  </>
                )}
              />
            </Col>
          </Row>
          <Divider />
          <ButtonWrap>
            <Row gutter={[20, 20]} justify="end">
              <Col xs={24} sm={24} md={6} lg={6} xl={3} xxl={3}>
                <StatusButton
                  loading={callBtnLoading}
                  bgColor="#58D255"
                  onClick={() => addLogAction("call")}
                >
                  <a href="tel:+14086343220">Call</a>
                </StatusButton>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={3} xxl={3}>
                <StatusButton
                  disabled={isPaystubVerified === 1}
                  loading={approveBtnLoading}
                  bgColor="#F67C36"
                  onClick={() => approvePaystub()}
                >
                  Approve
                </StatusButton>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={3} xxl={3}>
                <Dropdown
                  disabled={isPaystubVerified === 2}
                  trigger="click"
                  overlay={items}
                  placement="top"
                >
                  <StatusButton loading={denyBtnLoading} bgColor="#2B353F">
                    <span>Deny</span>
                  </StatusButton>
                </Dropdown>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={3} xxl={3}>
                <StatusButton
                  loading={ticketBtnLoading}
                  bgColor="#4160AC"
                  onClick={() => addLogAction()}
                >
                  <a href="mailto:someone@gmail.com">Open Ticket</a>
                </StatusButton>
              </Col>
            </Row>
          </ButtonWrap>
        </DetailPageWrap>
      </ContainerWrap>
    </Container>
  );
};

export default PayStubsDetailsPage;

export const DetailPageWrap = styled.div`
  h2 {
    color: #2b353f;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }

  h3 {
    margin-top: 30px;
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  h4 {
    color: ${({ fColor }) => fColor || "#4160AC"};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-form,
  .ant-form-horizontal {
    width: 100%;
  }

  .ant-list,
  .ant-list-split,
  .ant-list-bordered {
    height: calc(100vh - 360px);
    overflow: auto;
  }

  .save__btn {
    width: 100%;
  }

  .header__status {
    align-items: center;
  }

  .ant-divider-horizontal {
    margin: 20px 0;
  }
  .ant-divider-vertical {
    margin: 0 20px;
    height: calc(100vh - 360px);
  }
  @media (max-width: 991px) {
    .ant-divider-vertical {
      display: none;
    }
    .ant-list,
    .ant-list-split,
    .ant-list-bordered {
      margin: 24px 0;
    }
  }
`;

export const UploadBox = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #777777;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;

  @media (max-width: 1550px) {
    height: 65px;
  }
`;
export const UploadInput = styled.div`
  display: flex;
  height: 100%;
  background: #eeeeee;
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 15px;
  p {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #aaaaaa;
  }
  .upload__icon {
    color: ${({ theme }) => theme.colors.third};
  }
  .ant-upload-text {
    padding-right: 35px;
  }
  .ant-upload-drag-icon {
    padding-right: 5px;
    padding-top: 2px;
  }

  @media (max-width: 370px) {
    .upload__image {
      display: none;
    }
  }
`;
