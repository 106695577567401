import { Checkbox, Col, Input, Row, Switch, Table, message } from "antd";
import { httpAPIInviteSND } from "api";
import { DiscountTable } from "pages/Applications/Applications";
import { formatPhoneNumber } from "pages/utils/fn";
import useDebounce from "pages/utils/hooks/useDebounce";
import React, { useCallback, useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { useDispatch } from "react-redux";
import { activeDeactiveProperty } from "store/reducers/user.reducer";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import DeactivateProperty from "./DeactivateProperty";
import { useWindowSize } from "pages/utils/hooks/useWindowSize";

const Properties = () => {
  const [loading, setLoading] = useState(false);
  const [searchBarText, setSearchBarText] = useState("");
  const [orgName, setOrgName] = useState("");
  const [singleRowData, setSingleRowData] = useState({});
  const [switchLoading, setSwitchLoading] = useState(false);
  const [indexVal, setIndexVal] = useState("");
  const [modalState, setModalState] = useState(false);
  const [propertyArr, setPropertyArr] = useState([]);
  const [showDeactive, setShowDeactive] = useState("");
  const [bottomMargin, setBottomMargin] = useState("15px");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    size: "small",
    showSizeChanger: true,
  });
  const { width } = useWindowSize();
  const Search = Input.Search;
  const dispatch = useDispatch();
  const org_id = localStorage.getItem("org_id");
  const deBounceSearchBarText = useDebounce(searchBarText.trim(), 500);

  useEffect(() => {
    if (width > 991) {
      setBottomMargin("16px");
    } else {
      setBottomMargin("8px");
    }
  }, [width]);

  useEffect(() => {
    if (deBounceSearchBarText?.length >= 3) {
      setLoading(true);
      httpAPIInviteSND(
        `/properties-by-org?pageSize=${10}&pageNumber=${1}&id=${org_id}&query=${encodeURIComponent(
          deBounceSearchBarText
        )}&isActive=${showDeactive}`,
        "",
        "GET"
      ).then((res) => {
        setOrgName(res?.data?.org_name);
        setPagination((p) => ({ ...p, total: res?.data?.total }));
        let value = res?.data?.Data;
        const launchOptimistic = value?.map((elem, idx) => ({
          ...elem,
          key: idx,
        }));
        setPropertyArr(launchOptimistic);
        setLoading(false);
      });
    } else {
      let newPagination = { ...pagination };
      getProperty(newPagination, "", showDeactive);
    }
  }, [deBounceSearchBarText]);

  const getProperty = useCallback(
    (pagination, search = "", activate) => {
      setLoading(true);
      httpAPIInviteSND(
        `/properties-by-org?pageSize=${pagination.pageSize}&pageNumber=${
          pagination.pageNumber
        }&id=${org_id}&query=${encodeURIComponent(search)}&isActive=${
          activate ? activate : ""
        }`,
        "",
        "GET"
      )
        .then((res) => {
          setOrgName(res?.data?.org_name);
          setPagination((p) => ({ ...p, total: res?.data?.total }));
          let value = res?.data?.Data;
          const launchOptimistic = value?.map((elem, idx) => ({
            ...elem,
            key: idx,
          }));
          setPropertyArr(launchOptimistic);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error) {
            message.error(error.message);
          }
        });
    },
    [pagination]
  );

  const columns = [
    {
      title: "Property Name",
      dataIndex: "unit_name",
      key: "unit_name",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Zip Code",
      dataIndex: "zipcode",
      key: "zipcode",
    },
    {
      title: "Phone Number",
      dataIndex: "phonenumber",
      key: "phonenumber",
      render: (_, record) => {
        return (
          <div>
            <span>{formatPhoneNumber(record.phonenumber)}</span>
          </div>
        );
      },
    },
    {
      title: "#No of Units",
      dataIndex: "numberOfUnit",
      key: "numberOfUnit",
    },
    {
      title: "Actions",
      dataIndex: "",
      render: (_, record, index) => {
        return (
          <>
            <Switch
              loading={index === indexVal && switchLoading}
              checkedChildren="Active"
              unCheckedChildren="Deactive"
              defaultChecked={record?.activeStatus === "active" ? true : false}
              checked={record?.activeStatus === "active" ? true : false}
              onChange={(e) => {
                setIndexVal(index);
                if (record?.activeStatus === "active") {
                  setModalState(true);
                  setSingleRowData(record);
                } else {
                  onSwitchHandler(record);
                }
              }}
            />
          </>
        );
      },
    },
  ];

  const onSwitchHandler = (record) => {
    setSwitchLoading(true);
    let data = {};
    if (record?.activeStatus === "active") {
      const objIndex = propertyArr?.findIndex((obj) => {
        return obj.id === record.id;
      });
      let objValue = propertyArr[objIndex];
      objValue.activeStatus = "deactivate";
      propertyArr[objIndex] = objValue;
      data.id = record?.id;
      data.status = "0";
      dispatch(activeDeactiveProperty(data)).then(({ payload }) => {
        setSwitchLoading(false);
        let newPagination = { ...pagination };
        setModalState(false);
        getProperty(newPagination, "", showDeactive);
      });
    } else {
      const objIndex = propertyArr?.findIndex((obj) => {
        return obj.id === record.id;
      });
      let objValue = propertyArr[objIndex];
      objValue.activeStatus = "active";
      propertyArr[objIndex] = objValue;
      data.id = record?.id;
      data.status = "1";
      dispatch(activeDeactiveProperty(data)).then(({ payload }) => {
        setSwitchLoading(false);
        let newPagination = { ...pagination };
        getProperty(newPagination, "", showDeactive);
      });
    }
  };

  const emitEmpty = () => {
    setSearchBarText("");
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    setLoading(true);
    newPagination.pageNumber = newPagination.current;
    getProperty(newPagination, searchBarText, showDeactive);
    setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
  };

  const onSearch = (e) => {
    setSearchBarText(e.target.value);
  };

  const onChange = (e) => {
    if (e.target.checked) {
      setShowDeactive("0");
      let deactivatedProp = "0";
      let newPagination = {
        ...pagination,
        pageNumber: "1",
        pageSize: "10",
        current: "1",
      };
      getProperty(newPagination, deBounceSearchBarText, deactivatedProp);
    } else {
      setShowDeactive("");
      getProperty(pagination, deBounceSearchBarText, "");
    }
  };

  const suffix = <MdCancel onClick={() => emitEmpty()} />;

  return (
    <Container paddingSm="40px 40px 0 265px">
      <Row justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Heading>{orgName}</Heading>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={8}
          className="search__filter"
        >
          <Search
            ref={(ele) => searchBarText === ele}
            suffix={suffix}
            placeholder="Search..."
            value={searchBarText}
            onChange={onSearch}
            onPressEnter={onSearch}
          />
        </Col>
        <Col
          span={24}
          style={{ marginBottom: bottomMargin, paddingTop: "10px" }}
        >
          <Checkbox defaultChecked={false} onChange={(e) => onChange(e)}>
            Show only Deactivated Properties
          </Checkbox>
        </Col>
      </Row>
      <DiscountTable>
        <Table
          rowClassName={(record) =>
            record?.activeStatus === "deactivate" && "disabled-row"
          }
          columns={columns}
          dataSource={[...propertyArr]}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 1300, y: 550 }}
          onRow={(item) => ({
            onClick: (event) => {
              event.isDefaultPrevented();
            },
          })}
        />
      </DiscountTable>
      <DeactivateProperty
        onSwitchHandler={onSwitchHandler}
        singleRowData={singleRowData}
        switchLoading={switchLoading}
        onCancel={() => setModalState(false)}
        modalState={modalState}
      />
    </Container>
  );
};

export default Properties;
