import { Col, Modal, Row } from "antd";
import { DeactivateUserSvg } from "assets/svgs";
import { StyledButton } from "components/createPersonalProfile/RegistrationStyle";
import { Capitalize } from "pages/utils/fn";
import React from "react";
import { IoIosClose } from "react-icons/io";
import styled from "styled-components";

const ConfirmDeactivateMoal = ({
  modalState,
  onCancel,
  userName,
  onChangeHandler,
  modalBtnLoading = false,
}) => {
  return (
    <Modal
      getContainer={() => document.querySelector("#modal-container")}
      visible={modalState}
      onCancel={() => {
        onCancel(false);
      }}
      width={800}
      footer={null}
      // style={{ borderRadius: "5px", top: "50px" }}
      // className="modal_wrapper"
      closeIcon={<IoIosClose className="modal_close_icon" />}
      // maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.8)" }}
      //   bodyStyle={{ maxHeight: yScroll, overflow: "auto" }}
      // focusTriggerAfterClose={false}
      destroyOnClose={true}
    >
      <>
        <WarningWrap>
          {/* <DeactivateUserSvg /> */}
          <h1 className="modal__title">Deactivate User</h1>
          <h4 className="modal__content">
            Are you sure you want to Deactivate{" "}
            <span className="user__name">
              {" "}
              {`${Capitalize(userName?.firstName)} ${Capitalize(
                userName?.lastName
              )}`}{" "}
              ?
            </span>
          </h4>
          <h5 className="warning__msg">
            By Deactivating this account, User won't be able to access the
            Application.
          </h5>
          <Row justify="end" gutter={[20, 20]} style={{ marginTop: "24px" }}>
            <Col>
              <StyledButton hg="40px" onClick={() => onCancel(false)}>
                Cancel
              </StyledButton>
            </Col>
            <Col>
              <StyledButton
                hg="40px"
                loading={modalBtnLoading}
                onClick={() => onChangeHandler(userName)}
              >
                Yes
              </StyledButton>
            </Col>
          </Row>
        </WarningWrap>
      </>
    </Modal>
  );
};

export default ConfirmDeactivateMoal;

export const WarningWrap = styled.div`
  svg {
    width: 100%;
  }
  .modal__title {
    width: 100%;
    color: #2b353f;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
  }
  .modal__content {
    width: 100%;
    color: #777;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }
  .user__name {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .warning__msg {
    width: 100%;
    color: #f25b5b;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }
  @media (max-width: 550px) {
    .modal__title {
      font-size: 30px;
    }
    .modal__content {
      font-size: 20px;
    }
    .user__name {
      font-size: 24px;
    }
    .warning__msg {
      font-size: 16px;
    }
  }
`;
